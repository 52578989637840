import { UserParam } from 'api/user';
import PhsStyledButton from 'components/common/PhsStyledButton';
import CountryCodeInputs from 'components/fragment/countryCodeInput';
import ProfileInputSection from 'components/fragment/profileInputSection';
import Spinner from 'components/fragment/spinner';
import { ProfileDetailsInputListProps } from 'constants/profileDetails';
import { CheckedUser } from 'features/user-slice';
import {
    MDBAlert,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

interface ProfileDetailsProps {
    userInfo?: UserParam;
    checkedUser: CheckedUser;
    inputList: ProfileDetailsInputListProps[];
    inputChange;
    phoneNumberChange;
    flagChange;
    activateSaveButton: boolean;
    profileDetailSave: (event?) => void;
    closeError: () => void;
    showError?: boolean;
    errorMessage?: string;
    saveLoader?: boolean;
    saved: boolean;
    language: string;
}

const ProfileDetailsDisplay = (props: ProfileDetailsProps) => {
    const {
        inputChange,
        inputList,
        phoneNumberChange,
        flagChange,
        activateSaveButton,
        showError,
        errorMessage,
        saveLoader,
        saved,
        closeError,
        profileDetailSave,
        language,
    } = props;

    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    const inputs = inputList?.map((inputElement, index) => {
        const key = inputElement.id + index;

        /*
        if (inputElement.name === "phone_number") {
            return (
                <CountryCodeInputs
                    key={key}
                    horizontal
                    id="phone_number"
                    label={t(inputElement.label)}
                    defaultValue={inputElement?.value}
                    inputChange={phoneNumberChange}
                    flagChange={flagChange}
                />
            );
        }
            */

        return (
            <ProfileInputSection
                key={key}
                type={inputElement.type}
                id={inputElement.id}
                label={t(inputElement.label)}
                placeholder={t(inputElement.placeholder)}
                required={inputElement.required}
                name={inputElement.name}
                inputElementInput={inputChange}
                message={inputElement?.errorMessage || ""}
                readonly={inputElement.readonly}
                defaultValue={inputElement.value}
                horizontal
            />
        );
    });

    let saveMessage;
    const checkIcon = <MDBIcon fas icon="check" color="success" className="ms-1" />;

    saveLoader && (saveMessage = <Spinner inline size="sm" color="primary" />);
    saved && (saveMessage = <small className="text-success">saved{checkIcon}</small>);

    return (
        <div style={{ textAlign: "center" }}>
            <MDBAlert
                color="warning"
                position="top-center"
                className="w-100 d-flex justify-content-between"
                offset={0}
                // delay={2000}
                appendToBody
                show={showError}
            >
                <div>{errorMessage}</div>
                <MDBBtn className="btn-close" noRipple outline color="none" onClick={closeError} />
            </MDBAlert>
            {inputs}
            {saveMessage}
            <div style={{ marginTop: '32px' }}>
            <PhsStyledButton disabled={activateSaveButton} onClick={profileDetailSave} inverted width="120px">
                {t("저장")}
            </PhsStyledButton>
        </div>
        </div>
    );
};

export default ProfileDetailsDisplay;
