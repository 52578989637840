import { DeviceParams, TraitsResult } from "api/device";
import React, { useMemo, useState } from "react";
import { findTrait } from "utils";
import styled from "styled-components";
import { SYMBOL } from "constants/common";
import DeviceCardCommon from "./DeviceCardCommon";

export interface SsphsFragProps {
    device?: DeviceParams;
    deviceTraits?: TraitsResult;
    toggleSettingsModalHandler?: (event?) => void;
    deviceButtonClick?: (event?) => void;
    hover?: boolean;
}

const Trait = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 0.1rem;
    width: 90%; /* 전체 넓이를 줄여 가운데 정렬 */
    //margin-bottom: 1rem; /* 트레잇들 간의 세로 간격 추가 */
`;

const TraitTitle = styled.span`
    text-align: left;
    font-size: 1.2rem;
    flex: 5;
    margin: 0;
`;

const TraitValue = styled.span`
    text-align: left; /* 값 부분을 왼쪽 정렬 */
    font-size: 1.3rem;
    flex: 5;
    margin: 0;
`;

const HeatcableFragNew = (props: SsphsFragProps) => {

    const { device, deviceButtonClick } = props;

    const [isError, setIsError] = useState(false);
    const renderTraitNew = (json) => (
        <Trait key={json.title + json.value}>
            <TraitTitle>{json.title}</TraitTitle>
            <TraitValue>
                <span
                    style={{
                        fontWeight: json.isError ? "normal" : "bold",
                        //color: json.isError ? "red" : "white",
                        animation: json.isError ? "blink 1s step-end infinite" : "none",
                    }}
                >
                    {json.value}
                </span>
                <span style={{ fontSize: "1.1rem" }}> {json.unit}</span>
            </TraitValue>
        </Trait>
    );

    const renderTraits = useMemo(() => {
        const traits = device?.traits || [];

        const trait_on1 = findTrait(traits, "on1");
        const trait_curtemp1 = findTrait(traits, "curtemp1");
        const trait_line1_open = findTrait(traits, "line1_open");
        const trait_line1_short = findTrait(traits, "line1_short");
        const trait_line1_cable_open = findTrait(traits, "line1_cable_open");
        const trait_line1_cable_short = findTrait(traits, "line1_cable_short");

        const trait_on2 = findTrait(traits, "on2");
        const trait_curtemp2 = findTrait(traits, "curtemp2");
        const trait_line2_open = findTrait(traits, "line2_open");
        const trait_line2_short = findTrait(traits, "line2_short");
        const trait_line2_cable_open = findTrait(traits, "line2_cable_open");
        const trait_line2_cable_short = findTrait(traits, "line2_cable_short");

        const trait_ext_curtemp = findTrait(traits, "ext_curtemp");
        const trait_ext_open = findTrait(traits, "ext_open");
        const trait_ext_short = findTrait(traits, "ext_short");

        const makeJsonMessage = (trait) => {
            let json = {
                title: "",
                value: "",
                isError: false,
                unit: "",
            };
            switch (trait?.name) {
                case "on1":
                    json.title = "배관1";
                    if (trait_line1_open?.currentValue === true) {
                        json.value = "open";
                        json.isError = true;
                    } else if (trait_line1_short?.currentValue === true) {
                        json.value = "short";
                        json.isError = true;
                    } else {
                        json.value = trait?.currentValue ? "가동" : "정지";
                        json.isError = false;
                    }
                    break;
                case "on2":
                    json.title = "배관2";
                    if (trait_line2_open?.currentValue === true) {
                        json.value = "open";
                        json.isError = true;
                    } else if (trait_line2_short?.currentValue === true) {
                        json.value = "short";
                        json.isError = true;
                    } else {
                        json.value = trait?.currentValue ? "가동" : "정지";
                        json.isError = false;
                    }
                    break;
                case "curtemp1":
                    json.title = "배관1 온도";
                    if (trait_line1_cable_open?.currentValue === true) {
                        json.value = "open";
                        json.isError = true;
                    } else if (trait_line1_cable_short?.currentValue === true) {
                        json.value = "short";
                        json.isError = true;
                    } else {
                        json.value = trait?.currentValue;
                        //json.unit = trait.unit;
                        json.unit = SYMBOL.TEMP;
                        json.isError = false;
                    }
                    break;
                case "curtemp2":
                    json.title = "배관2 온도";
                    if (trait_line2_cable_open?.currentValue === true) {
                        json.value = "open";
                        json.isError = true;
                    } else if (trait_line2_cable_short?.currentValue === true) {
                        json.value = "short";
                        json.isError = true;
                    } else {
                        json.value = trait?.currentValue;
                        json.unit = SYMBOL.TEMP;
                        json.isError = false;
                    }
                    break;
                case "ext_curtemp":
                    json.title = "외기 온도";
                    if (trait_ext_open?.currentValue === true) {
                        json.value = "open";
                        json.isError = true;
                    } else if (trait_ext_short?.currentValue === true) {
                        json.value = "short";
                        json.isError = true;
                    } else {
                        json.value = trait?.currentValue;
                        //json.unit = trait.unit;
                        json.unit = SYMBOL.TEMP;
                        json.isError = false;
                    }
                    break;
                default:
                    break;
            }

            return json;
        };

        let finalStateJson = {
            title: "상태",
            value: "",
            unit: "",
            isError: false,
        };

        if (
            trait_line1_open?.currentValue === true ||
            trait_line1_short?.currentValue === true ||
            trait_line1_cable_open?.currentValue === true ||
            trait_line1_cable_short?.currentValue === true ||
            trait_line2_open?.currentValue === true ||
            trait_line2_short?.currentValue === true ||
            trait_line2_cable_open?.currentValue === true ||
            trait_line2_cable_short?.currentValue === true ||
            trait_ext_open?.currentValue === true ||
            trait_ext_short?.currentValue === true
        ) {
            finalStateJson.value = "error";
            finalStateJson.isError = true;
            setIsError(true);
        } else {
            finalStateJson.value = "정상";
            finalStateJson.isError = false;
            setIsError(false);
        }
        return (
            <>
                {renderTraitNew(makeJsonMessage(trait_on1))}
                {renderTraitNew(makeJsonMessage(trait_on2))}
                {renderTraitNew(makeJsonMessage(trait_curtemp1))}
                {renderTraitNew(makeJsonMessage(trait_curtemp2))}
                {renderTraitNew(makeJsonMessage(trait_ext_curtemp))}
                {renderTraitNew(finalStateJson)}
            </>
        );
    }, [device?.traits]);

    return (
        <DeviceCardCommon
            isError={isError}
            device={device}
            children={renderTraits}
            deviceButtonClick={deviceButtonClick}
        />
    );
};

export default React.memo(HeatcableFragNew);
