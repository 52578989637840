import IconBtn from 'components/fragment/iconBtn';
import {
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalHeader,
    MDBModalTitle,
} from 'mdb-react-ui-kit';
import styled from 'styled-components';
import icButtonClose from 'assets/img/icons/ic_button_close.png';

const CloseBtn = styled.button`
    border: none;
    background-color: transparent;
    padding: 0px; /* 테두리와 X 간의 간격 */
    cursor: pointer; /* 포인터 커서 */
    position: absolute; /* 절대 위치 */
    top: 50px; /* 상단에서 50px 떨어진 위치 */
    right: 50px; /* 오른쪽 끝에서 50px 떨어진 위치 */
`;

const CloseIconButton = ({ ariaLabel, onClick }) => (
    <CloseBtn onClick={onClick} aria-label={ariaLabel}>
        <img src={icButtonClose} alt="" />
    </CloseBtn>
);

const ConnectedDeviceModal = (props) => {
    const { showModal, setShowModal, modalTitle, toggleModalHandler } = props;

    return (
        <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
            <MDBModalDialog
                centered
                size='lg'
                scrollable
                style={{
                    maxWidth: '1000px',
                }}
            >
                <MDBModalContent
                style={{
                    borderRadius: "0",
                    padding: "80px 26px",
                    }}>
                    <MDBModalHeader
                    className='modal-header'
                    style={{
                        display: 'flex',
                        justifyContent: 'center', // 가운데 정렬
                        /* border: 'none' */
                        }}
                    >
                        <span></span>
                        <MDBModalTitle
                        className='modal-title'
                        style={{
                            fontSize: "2.6rem",
                            color: "#191919",
                            fontWeight: "bold",
                        }}
                        >
                            {modalTitle}
                        </MDBModalTitle>
                        {/* <IconBtn
                            type='close'
                            ariaLabel='Close'
                            onClick={toggleModalHandler}
                        /> */}
                        <CloseIconButton ariaLabel="Close" onClick={toggleModalHandler} />
                    </MDBModalHeader>
                    <MDBModalBody className='px-4 py-0'>
                        {props.children}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};

export default ConnectedDeviceModal;
