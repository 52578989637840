import styled from '@emotion/styled';
import { AlarmParams, queryTraitGetAlarm, queryTraitPutAlarm } from "api/alarm";
import { DeviceParams, queryPutDeviceSetting, queryPutPlace, Traits } from "api/device";
import { getPlaceDevices } from 'api/place';
import r9iot, { setChildState } from 'api/r9iot';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ToggleSwitch from 'components/common/ToggleSwitch';
import CustomSlider from 'components/fragment/CustomSlider';
import MultiRangeSection, { temperatureRange } from 'components/fragment/multiRangeSection';
import NormalInputSection from 'components/fragment/normalInputSection';
import config from 'config';
import { Application, defaultReportTime } from 'constants/application';
import { TRAIT_TYPE } from 'constants/common';
import { DEVICE_TYPE_SSPHS } from 'constants/deviceTypeConst';
import { updateCurrentDeviceState, updateDeviceList } from 'features/display-slice';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeviceSettingHeatcable from './deviceSettingHeatcable';

export interface DeviceSettingDisplayProps {
    currentDevice: DeviceParams;
}

export interface SettingInputs {
    nickname?: string;
    reporttime?: number;
    placeUuid?: string;
}

export interface AlertState {
    name: string;
    stackingCount: number;
    message?: string;
}

interface ToggleProps {
    isSelected: boolean;
    handleToggle: () => void;
    disabled?: boolean;
}

const StyledContents = styled.div`
    width: 1443px;
`;
const StyledContentHeader = styled.div`
    display: flex;
    justify-content: space-between; /* 아이템 사이의 간격을 자동으로 균등 배치 */
    /* align-items: center; 수직 정렬 */
    gap: 0rem; /* 1rem; 아이템 간 간격 */
    color: white !important;
`;
const HeaderItem = styled.div`
    /* flex: 1; */
`;
const StyledContentBody = styled.div` /* 열선제어기block */
    align-items: center; /* 수직 정렬 */
    color: white !important;
`;
const StyledTraitItem = styled.div` /* 열선제어기block 5줄 */
    width : 100%;
    display: flex;
    align-items: stretch; /* 자식 요소가 부모 높이를 모두 차지하게 */
    background-color: #2c2c2c;
    padding: 0;
    height: 80px; /* 부모 요소에 명시적인 높이를 부여 */
    margin: 1.2rem 0rem; /* 1rem; */
`;

const DivSwitch = styled.div` /* 왼쪽 title */
    width: 15%; /* 왼쪽 10% 너비 */
    height: 100%;
    display: flex;
    flex-direction: column; /* 요소들을 세로로 정렬 */
    justify-content: center;
    align-items: center;
    border-right: 1px solid #7c7c7c; /* border-right: 1px solid white; */
`;

const DivSlider = styled.div` /* 오른쪽 data */
    width: 85%; /* 오른쪽 90% 너비 */
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 80px;
    gap: 10px;
`;

const StyledMDBBtn = styled(MDBBtn)`
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important; /* 그림자 제거 */
    //margin: 0.5rem;
    &:hover {
        background-color: transparent !important;
    }
    font-size: 1rem;
    font-weight: Medium; /* bold; */
`;

const Spacer = styled.span`
    margin: 0 0.5rem; /* 양쪽에 0.5rem 공백 추가 */
    font-size: 1rem;
    font-weight: Medium; /* bold; */
`;

const Backslash = styled.span`
    margin: 0 3rem; /* 양쪽에 0.5rem 공백 추가 */
    font-size: 1rem;
    font-weight: Medium; // font-weight: Bold;
    color: #c6c6c6; 
`;

const StyledButtonLabel = styled.span`
`;

// CSS 추가
const MultiRangeSectionContainer = styled.div`  // 전체
    display: flex;
    width: 94%;
`;

const LabelContainer = styled.div`
    // 첫 번째 섹션 (20%)
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    padding: 0rem; // padding: 0rem 1rem;
`;

const SliderContainer = styled.div`
    // 두 번째 섹션 (60%)
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
`;

const ChipContainer = styled.div`
    // 세 번째 섹션 (20%)

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0rem;
    //padding: 0rem 1rem 0rem 1rem;
`;

const StyledChip = styled.div`
    // 세 번째 섹션 '온도' bg
    background-color: #5c5c5c; /* 회색 배경색 */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 50px; /* 타원형으로 보이게 큰 라운드 */
    text-align: center;
    width: 90px; /* 고정된 너비 */
    height: 60px; /* 고정된 높이 */
    display: flex;
    flex-direction: column; /* 텍스트를 위아래로 배치 */
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.2;
`;

const ChipLabel = styled.div`
    // 세 번째 섹션 '온도' title
    font-size: 1rem;
    margin-bottom: 0.1rem;
`;

const ChipValue = styled.div`
    font-size: 1rem;
    font-weight: normal;
`;

const DeviceSettingDisplay = (props: DeviceSettingDisplayProps) => {
    const { currentDevice } = props;

    const { t, i18n } = useTranslation(["trans"]);
    const navigate = useNavigate();
    /* from redux */

    const dispatch = useAppDispatch();
    const currentLocationId = useAppSelector((state) => state.authState.currentLocationId);
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const currentDeviceState = useAppSelector((state) => state.display.currentDeviceState);
    const placeList = useAppSelector((state) => state.display.placeList);
    const [placeData, setPlaceData] = useState<Application[]>([]);

    // 상태 추가
    const [externalTemp, setExternalTemp] = useState(undefined); // 외기온도 초기값 없음
    const [pipeTemp, setPipeTemp] = useState(undefined); // 배관온도 초기값
    const [shiftTime, setShiftTime] = useState(undefined); // 교대 시간 초기값 없음

    /**
     * button test values
     */

    const [isSelectedPower, setIsSelectedPower] = useState<boolean>(false);
    const [isSelectedAuto, setIsSelectedAuto] = useState<boolean>(false);
    const [isSelectedAlarm, setIsSelectedAlarm] = useState<boolean>(false);
    const [isSelectedChange, setIsSelectedChange] = useState<boolean>(false);
    const [isSelectedLine1On, setIsSelectedLine1On] = useState<boolean>(false);
    const [isSelectedLine2On, setIsSelectedLine2On] = useState<boolean>(false);

    useEffect(() => {
        const array = Object.keys(placeList).map((index) => {
            const place = placeList[index];
            return { value: place?.uuid, text: place?.nickname };
        });
        //console.log("array", array);
        setPlaceData(array);
    }, [placeList]);

    /* display state start */
    const [currentDeviceTraitList, setCurrentDeviceTraitList] = useState<Traits[]>(null);
    const [alarms, setAlarms] = useState(null);

    const [inputs, setInputs] = useState<SettingInputs>(null);
    const [alertState, setAlertState] = useState<AlertState>({
        name: "",
        stackingCount: 0,
        message: "",
    });

    /* display state end */

    useEffect(() => {
        console.log("currentDevice", currentDevice);
        const settings = {
            nickname: currentDevice?.nickname || "",
            reporttime: currentDeviceState?.mode?.reporttime / defaultReportTime || 0,
            placeUuid: currentDevice?.subscribed ? currentLocationId : null,
        };

        setInputs(settings);
    }, [currentDevice, currentDeviceState, currentLocationId]);

    useEffect(() => {
        if (!currentDevice) return;

        async function getAlarmCondition() {
            const newTraitList: Traits[] = [];
            let model = config.models[currentDevice.modelCode];
            /**
             * config.js 의 alarmFilter에 해당되는 trait만 가져다가 그림을 그린다.
             */
            //console.log("currentDevice.traits", currentDevice.traits);
            const promiseList = Object.keys(currentDevice.traits)
                .filter((uuid) => {
                    let tr = currentDevice.traits[uuid];
                    return !!model.alarmFilter.includes(tr.name);
                })
                .map((uuid) => {
                    const trait = currentDevice.traits[uuid];

                    newTraitList.push(trait);

                    return queryTraitGetAlarm(checkedUser.principalId, uuid)
                        .then((response) => {
                            return {
                                ...response.data,
                                traitUuid: uuid,
                                name: trait.name,
                            };
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                });

            const alarmResult: AlarmParams[] = await Promise.all(promiseList);

            if (!alarmResult) return;

            setAlarms(alarmResult);

            //console.log("newTraitList", newTraitList);
            setCurrentDeviceTraitList(
                newTraitList.sort((a, b) => {
                    const na = a.nickname;
                    const nb = b.nickname;
                    return na < nb ? -1 : na > nb ? 1 : 0;
                })
            );
        }

        async function getDeviceState() {
            const result = await r9iot
                .getChildState(checkedUser.principalId, currentDevice.id)
                .catch((error) => console.info(error));

            if (!result) return;

            dispatch(updateCurrentDeviceState(result.data));
        }

        getAlarmCondition();
        getDeviceState();
    }, [checkedUser, currentDevice, dispatch]);

    const findAlarm = useCallback(
        (name) => {
            return alarms.find((alarm) => alarm.name === name);
        },
        [alarms]
    );

    const toggleSwitchHandler = useCallback(
        (name: string) => {
            const alarm = findAlarm(name);
            const changeValue = !alarm.alarm;

            setAlarms(
                alarms.map((alarmInfo) => {
                    if (alarmInfo.name === name) {
                        return {
                            ...alarmInfo,
                            alarm: changeValue,
                        };
                    } else {
                        return alarmInfo;
                    }
                })
            );
        },
        [alarms, findAlarm]
    );

    const changeInputHandler = (event, name) => {
        const value = event.target.value;

        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const updatePlaceDevices = useCallback(
        (principalId: string, locationId: string) => {
            async function getDevices() {
                const result = await getPlaceDevices(principalId, locationId).catch((error) => {
                    console.info("Fail to fetch place device.");
                    console.info(error);
                });

                dispatch(updateDeviceList(result));
            }

            getDevices();
        },
        [dispatch]
    );

    const focusOutChangeHandler = (event, name: string) => {
        const value = event.target.value;
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const slideRangeInputHandler = useCallback(
        (event, value: [number, number]) => {
            const target = event.target.querySelector("input");
            const name = target?.name;
            const alarmCondition = {
                min: value[0],
                max: value[1],
            };

            setAlarms(
                alarms.map((alarmInfo) => {
                    if (alarmInfo.name === name) {
                        return {
                            ...alarmInfo,
                            alarmCondition: alarmCondition,
                        };
                    } else {
                        return alarmInfo;
                    }
                })
            );
        },
        [alarms]
    );

    const selectValueChangeHandler = (event) => {
        const value = event.target.value;
        setInputs({
            ...inputs,
            placeUuid: value,
        });
    };

    const saveChanges = async () => {
        if (!currentDevice) return;

        const { nickname, reporttime, placeUuid } = inputs;

        // Save nickname
        if (nickname !== currentDevice?.nickname) {
            await queryPutDeviceSetting(checkedUser.principalId, currentDevice.uuid, {
                nickname: nickname !== "" ? nickname : currentDevice?.name,
            }).catch((error) => {
                console.info("Fail to change device setting");
                console.info(error);
            });
        }

        console.log("saveChanges placeUuid", placeUuid);
        // Save place
        if (placeUuid) {
            await queryPutPlace(checkedUser.principalId, currentDevice.uuid, { placeUuid }).catch((error) => {
                console.info("Fail to change device place");
                console.info(error);
            });
        }

        // Save alarms
        for (const alarm of alarms) {
            await queryTraitPutAlarm(checkedUser.principalId, alarm.traitUuid, { alarm: alarm.alarm }).catch((error) =>
                console.info(error)
            );
            if (alarm.alarm === true && alarm.alarmCondition !== null) {
                await queryTraitPutAlarm(checkedUser.principalId, alarm.traitUuid, {
                    alarmCondition: alarm.alarmCondition,
                }).catch((error) => console.info(error));
            }
        }

        // Save report time
        if (currentDevice?.type !== "ssphs") {
            if (reporttime * defaultReportTime !== currentDeviceState?.mode?.reporttime) {
                await setChildState(checkedUser.principalId, currentDevice.id, {
                    mode: { reporttime: reporttime * defaultReportTime },
                }).catch((error) => {
                    console.log("Fail to change state of hub child device.");
                });
            }
        }

        updatePlaceDevices(checkedUser.principalId, currentLocationId);

        setAlertState({
            name: "save",
            stackingCount: ++alertState.stackingCount,
            message: t("Changes saved successfully"),
        });

        // 메인으로 이동
        navigate("/");
    };
    const findTraitByName = useCallback(
        (name) => {
            return currentDeviceTraitList?.find((trait) => trait.name === name);
        },
        [currentDeviceTraitList]
    );

    const renderTraitItem = useCallback(
        (trait) => {
            if (!trait) return;
            const alarmInfo = findAlarm(trait.name);

            let alarmName = trait.name;
            switch (currentDevice?.modelCode) {
                case "r9iot-ssphs":
                    switch (currentDevice?.attributes?.device_type) {
                        case DEVICE_TYPE_SSPHS.DUST:
                            if (trait.name === "sensor1") alarmName = "미세먼지1";
                            if (trait.name === "sensor2") alarmName = "미세먼지2";
                            if (trait.name === "sensor3") alarmName = "미세먼지3";
                            break;
                        case DEVICE_TYPE_SSPHS.PH:
                            if (trait.name === "sensor1") alarmName = "PH";
                            break;
                        case DEVICE_TYPE_SSPHS.CO2:
                            if (trait.name === "sensor1") alarmName = "CO2";
                            break;
                        case DEVICE_TYPE_SSPHS.NH3:
                            if (trait.name === "sensor1") alarmName = "NH3";
                            break;
                        case DEVICE_TYPE_SSPHS.SOIL:
                            if (trait.name === "sensor1") alarmName = "토양수분";
                            break;
                        case DEVICE_TYPE_SSPHS.EXTERNAL:
                            if (trait.name === "sensor1") alarmName = "외부온도1";
                            if (trait.name === "sensor2") alarmName = "외부온도2";
                            break;
                    }
                    break;
                default:
                    alarmName = trait.name;
                    break;
            }
            return (
                <StyledTraitItem key={alarmName}>
                    <DivSwitch>
                        <StyledButtonLabel>
                            {t(`${alarmName.toUpperCase()}`) + " " + t("LOCAD0056", "알람")}
                        </StyledButtonLabel>
                        <ToggleSwitch
                            isSelected={alarmInfo.alarm}
                            handleToggle={() => {
                                console.log("onClick button", trait.name);
                                toggleSwitchHandler(trait.name);
                            }}
                        />
                        {/**
                    <SwitchSection
                        label={t(`${alarmName.toUpperCase()} ALARM`)}
                        id={alarmName}
                        name={alarmName}
                        checked={alarmInfo.alarm}
                        toggleSwitchHandler={() => toggleSwitchHandler(alarmName)}
                    />
                    */}
                    </DivSwitch>
                    <DivSlider>
                        {trait.type === TRAIT_TYPE.LEVEL_SENSOR && (
                            <MultiRangeSection
                                trait={trait}
                                label={t(`${alarmName.toUpperCase()}`) + " " + t("LOCAD0057", "범위")}
                                slide={slideRangeInputHandler}
                                alarmCondition={alarmInfo.alarmCondition}
                                disabled={!alarmInfo.alarm}
                            />
                        )}
                    </DivSlider>
                </StyledTraitItem>
            );
        },
        [
            currentDevice?.attributes?.device_type,
            currentDevice?.modelCode,
            findAlarm,
            slideRangeInputHandler,
            t,
            toggleSwitchHandler,
        ]
    );

    const renderTraitsForDevice = useCallback(
        (traitNames) => {
            return traitNames.map((name) => renderTraitItem(findTraitByName(name)));
        },
        [findTraitByName, renderTraitItem]
    );

    const renderItemsByDeviceType = useMemo(() => {
        let renderer = <></>;

        switch (currentDevice?.type) {
            case "heatcable":
                renderer = (
                    <DeviceSettingHeatcable />
                );
                break;
            case "ssphs":
                switch (currentDevice?.attributes?.device_type) {
                    case DEVICE_TYPE_SSPHS.DUST:
                        renderer = (
                            <>{renderTraitsForDevice(["temperature", "humidity", "sensor1", "sensor2", "sensor3"])}</>
                        );
                        break;
                    case DEVICE_TYPE_SSPHS.PH:
                    case DEVICE_TYPE_SSPHS.CO2:
                    case DEVICE_TYPE_SSPHS.NH3:
                    case DEVICE_TYPE_SSPHS.SOIL:
                        renderer = <>{renderTraitsForDevice(["temperature", "humidity", "sensor1"])}</>;
                        break;
                    case DEVICE_TYPE_SSPHS.EXTERNAL:
                        renderer = <>{renderTraitsForDevice(["temperature", "humidity", "sensor1", "sensor2"])}</>;
                        break;
                    default:
                        renderer = <>{renderTraitsForDevice(["temperature", "humidity"])}</>;
                        break;
                }
                break;
            default:
                renderer = (
                    <>
                        {currentDeviceTraitList?.map((trait) => {
                            return renderTraitItem(trait);
                        })}
                    </>
                );
                break;
        }

        return renderer;
    }, [
        currentDevice?.type,
        currentDevice?.attributes?.device_type,
        isSelectedPower,
        isSelectedAuto,
        isSelectedAlarm,
        isSelectedChange,
        isSelectedLine1On,
        isSelectedLine2On,
        externalTemp,
        pipeTemp,
        shiftTime,
        currentDeviceTraitList,
        renderTraitsForDevice,
        renderTraitItem,
    ]);

    return (
        <>
            <MDBContainer
                fluid
                className="subpage-container pt-2 px-lg-5"
                style={{ overflowX: 'auto' }} // 가로 스크롤을 활성화
            >
                <div className="label-header">
                    <div className="d-flex align-items-center">
                        <div>
                            <h2 className="m-0">{/* <h2 className="m-3"> */}
                                {t("LOCAD0007", "설정 변경")} {" | "}
                            </h2>
                        </div>
                        <div className="alarm-device-info text-nowrap text-grayish">
                            <div>
                                <small className="me-3">
                                    {t("LOCAD0023", "시리얼번호")}: {currentDevice?.deviceInfo.serial}
                                </small>
                            </div>
                            <div>
                                <small className="me-3">
                                    {t("LOCAD0024", "장치이름")}: {currentDevice?.nickname}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="ml-auto">
                        <StyledMDBBtn rounded={false} outline={false} onClick={saveChanges} className="me-2" size="sm">
                            {t("LOCAD0008", "Save")}
                        </StyledMDBBtn>
                        <Spacer>|</Spacer>
                        <StyledMDBBtn
                            rounded={false}
                            outline={false}
                            onClick={() => {
                                navigate(-1);
                            }}
                            className="me-2"
                            size="sm"
                        >
                            {t("LOCAD0009", "Cancel")}
                        </StyledMDBBtn>
                    </div>
                </div>

                {/** Body */}
                <StyledContents>
                    {/** Body input section */}
                    <StyledContentHeader>
                        <HeaderItem>
                            <NormalInputSection
                                type="text"
                                from="setting"
                                id="nickname"
                                horizontal={true}
                                label={t("장치 이름(닉네임)")}
                                placeholder="장치 이름(닉네임)" /* placeholder="Thermo" */
                                name="nickname"
                                value={inputs?.nickname}
                                focusOut={focusOutChangeHandler}
                                inputChange={changeInputHandler}
                            />
                        </HeaderItem>

                        <HeaderItem>
                            <NormalInputSection
                                id="place"
                                type="select"
                                from="setting"
                                horizontal={true}
                                name="locationSelect"
                                label="장소"
                                data={placeData}
                                value={inputs?.placeUuid || currentLocationId}
                                valueChanged={selectValueChangeHandler}
                            />
                        </HeaderItem>
                        <HeaderItem>
                            <NormalInputSection
                                type="number"
                                from="setting"
                                id="reporttime"
                                label={t("데이터 저장 주기(분)")}
                                name="reporttime"
                                horizontal
                                value={inputs?.reporttime}
                                focusOut={focusOutChangeHandler}
                                inputChange={changeInputHandler}
                                small
                                disabled={currentDevice?.type === "ssphs" ? true : false}
                            />
                        </HeaderItem>
                    </StyledContentHeader>
                    {/** Body traits */}
                    <StyledContentBody>{renderItemsByDeviceType}</StyledContentBody>
                </StyledContents>
            </MDBContainer>
        </>
    );
};

export default DeviceSettingDisplay;