import styled from '@emotion/styled';
import { Button, Menu, MenuItem, MenuProps } from '@mui/material';
import { PlaceParams } from 'api/place';
import { AlarmListParams } from 'api/record';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import logo from 'assets/img/nemoahn_logo_title.png';
import InfiniteScroll from 'components/fragment/infiniteScroll';
import { COLOR_MAIN_BG, SIDENAV_WIDTH } from 'constants/common';
import { Languages, languageType } from 'constants/localizer';
import { updateCurrentDevice } from 'features/display-slice';
import { updateLanguage } from 'features/language-slice';
import { updateEndDate, updateStartDate } from 'features/search-slice';
import { MDBContainer, MDBIcon, MDBNavbar } from 'mdb-react-ui-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';


const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 0,
        background: "rgba(255, 255, 255, 1)", // 반투명한 흰색 배경
        boxShadow: "none",
        overflow: "visible",
    },
    "& .MuiList-root": {
        display: "flex",
        justifyContent: "center",
        alignItems: "left",
        flexDirection: "column",
        padding: 0,
    },
    "& .MuiMenuItem-root": {
        //width: '100%',
        textAlign: "left",
        padding: "8px 16px",
    },
}));

const StyledMDBNavbar = styled(MDBNavbar)`
    height: 125px !important;
    display: flex;
    align-items: flex-end;
    padding-bottom: 16px;
    background: ${COLOR_MAIN_BG} !important;
`;

const CenteredNavLink = styled(NavLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${SIDENAV_WIDTH}; /* Match this value to the width of the side nav */
    height: 100%;
`;

const StyledTopMenuButton = styled(Button)`
    //border: 1px solid red;
    color: white !important;
    font-size: 1rem; /* 원하는 크기로 조정 */
    font-weight: bold !important;

    &:hover {
        background-color: inherit !important; /* 배경색 변경 방지 */
        color: white !important; /* 텍스트 색상 유지 */
    }

    white-space: nowrap;  /* 텍스트가 줄바꿈되지 않도록 설정 */
    overflow: hidden;     /* 넘치는 텍스트 숨기기 (선택사항) */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...으로 표시 (선택사항) */
`;

interface TopbarProps {
    signout: (event?: React.MouseEvent) => void;
    toggleAddLocationModal?: () => void;
    toggleAddDeviceModal?: () => void;
    navList: PlaceParams[];
    navigateClick: (location: PlaceParams) => void;
}

const Topbar = (props: TopbarProps) => {
    const { toggleAddDeviceModal, toggleAddLocationModal, navList, navigateClick } = props;

    /* props from react-router */
    const navigate = useNavigate();
    const { locationId } = useParams();

    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    /* props from redux */
    const dispatch = useAppDispatch();
    const currentLocationId = useAppSelector((state) => state.authState.currentLocationId);
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);
    const alarmList = useAppSelector((state) => state.display.alarmList);
    const allDeviceList = useAppSelector((state) => state.display.allDeviceList);
    const language = useAppSelector((state) => state.language.language);

    /* local state */
    const [showIcon, setShowIcon] = useState<boolean>(false);

    const [anchorElPlace, setAnchorElPlace] = useState<null | HTMLElement>(null);
    const [anchorElAccount, setAnchorElAccount] = useState<null | HTMLElement>(null);
    const [anchorElAlarm, setAnchorElAlarm] = useState<null | HTMLElement>(null);
    const [languageButtonElement, setLanguageButtonElement] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorElPlace);
    const open2 = Boolean(anchorElAccount);
    const open3 = Boolean(anchorElAlarm);
    const isLanguageButtonOpen = Boolean(languageButtonElement);

    /* topbar 메뉴에서 dropdown이 활성화 된 상태에서 다른 topbar-item을 선택할 시, 
    현재 활성화된 dropdown을 비활성화 시키고, 클릭한 dropdown을 활성화 시키는 함수들 */
    /* handle click */
    const handleClickPlace = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElPlace(event.currentTarget);
    };


    const [isHovered, setIsHovered] = useState(false);
    const handleClickAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isHovered) {
            console.log("account button on over");
            setAnchorElAccount(event.currentTarget);
            setIsHovered(true); // 마우스가 올라간 상태로 설정
        }
    };
    const handleCloseAccount = () => {
        if (isHovered) {
            console.log("close account");
            setAnchorElAccount(null);
            setIsHovered(false); // 마우스가 나간 상태로 설정
        }
    };


    const handleClickAlarm = (event) => {
        if (anchorElAlarm !== event.currentTarget) {
            setAnchorElAlarm(event.currentTarget);
        }
    };
    const handleCloseAlarm = () => {
        setAnchorElAlarm(null);
    };


    const handleClickLanguage = (event) => {
        setLanguageButtonElement(event.currentTarget);
    };

    const handleClosePlace = () => {
        setAnchorElPlace(null);
    };
    const closeLanguageMenu = () => {
        setLanguageButtonElement(null);
    };
    /* handle click */

    /* add location */
    function toggleAddLocationModalHandler() {
        toggleAddLocationModal();
        setAnchorElPlace(null);
    }

    function signoutHandler() {
        handleCloseAccount();
        props.signout();
    }

    function navigateHandler(location: PlaceParams) {
        setAnchorElPlace(null);
        navigateClick(location);
    }

    const displayNavList = navList?.map((navItem: PlaceParams, index: number) => {
        const className =
            navItem.uuid === locationId || navItem.uuid === currentLocationId ? " text-white bg-primary" : "";

        return (
            <MenuItem className={className} key={navItem.uuid + "_" + index} onClick={() => navigateHandler(navItem)}>
                <MDBIcon className="me-3" fas icon="compass" />
                {navItem.nickname}
            </MenuItem>
        );
    });

    // @ts-ignore
    /*
    // 수정사항.xls 요청사항으로 아이콘으로 변경없이 글자메뉴로만 표현되도록.
    const handleDisplay = useCallback(() => {
        if (window.matchMedia("(max-width: 800px)").matches) {
            setShowIcon(true);
        } else {
            setShowIcon(false);
        }
    }, []);

    useEffect(() => {
        handleDisplay();

        window.addEventListener("resize", handleDisplay);
    }, []);
    */

    function AlarmItemClickHandler(alarm: AlarmListParams) {
        const find = allDeviceList.find((device) => device.uuid === alarm.deviceUuid);

        // 펼쳐진 알람 목록 리스트 닫기
        handleCloseAlarm();

        // 검색을 위해 전역 상태에 데이터 주입
        // 알람이 발생한 시간을 시작,끝으로 넣기때문에 해당알람 1개만 검색이 되는 구조
        dispatch(updateCurrentDevice(find));
        dispatch(updateStartDate({ date: alarm.date, string: null }));
        dispatch(updateEndDate({ date: alarm.date, string: null }));

        // 페이지 이동하면 자동으로 전역상태에 있는 값으로 검색이 됨.
        navigate("/dashboard/alarms");
    }

    function languageSelectHandler(language: languageType) {
        dispatch(updateLanguage(language.name));
        i18n.changeLanguage(language.i18nType);
    }

    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

const handleLanguageMenuOpen = () => {
    setIsLanguageMenuOpen(true);
};

const handleLanguageMenuClose = () => {
    setIsLanguageMenuOpen(false);
};

    return (
        <StyledMDBNavbar expand="lg" dark bgColor="dark" sticky className="topbar" style={{ height: "125px" }}>
            <MDBContainer fluid className="d-flex align-items-center">
                <CenteredNavLink className="navbar-brand" to="/">
                    <img src={logo} alt="" height="30" loading="lazy" />
                </CenteredNavLink>

                <div
                    className="d-flex"
                    style={{
                        margin: "0 5%",
                        gap: "50px", // 버튼들 사이의 간격 조정
                    }}
                >
                    <StyledTopMenuButton
                        id="basic-button"
                        className="text-body me-2"
                        aria-controls={isLanguageButtonOpen ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={isLanguageButtonOpen ? "true" : undefined}
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        {showIcon ? <MDBIcon fas icon="home" /> : t("LOCAD0014", "홈")}
                    </StyledTopMenuButton>

                    {/**
                    <StyledTopMenuButton
                        id="language-button"
                        className="text-body me-2"
                        aria-controls={isLanguageButtonOpen ? "language-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={isLanguageButtonOpen ? "true" : undefined}
                        onClick={handleClickLanguage}
                    >
                        {showIcon ? <MDBIcon fas icon="globe" /> : t("언어")}
                    </StyledTopMenuButton>
                    <StyledMenu
                        id="language-menu"
                        anchorEl={languageButtonElement}
                        open={isLanguageButtonOpen}
                        onClose={closeLanguageMenu}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        MenuListProps={{
                            "aria-labelledby": "language-button",
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                closeLanguageMenu();
                                languageSelectHandler(Languages.KOR);
                            }}
                        >
                            한국어
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                closeLanguageMenu();
                                languageSelectHandler(Languages.ENG);
                            }}
                        >
                            English
                        </MenuItem>
                    </StyledMenu>
 */}

                    {/**
                    <StyledTopMenuButton
                        id="place-button"
                        className="text-body me-2"
                        aria-controls={open ? "place-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickPlace}
                    >
                        {showIcon ? <MDBIcon fas icon="compass" /> : t("장소")}
                    </StyledTopMenuButton>
                    <StyledMenu
                        id="place-menu"
                        anchorEl={anchorElPlace}
                        open={open}
                        onClose={handleClosePlace}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        MenuListProps={{
                            "aria-labelledby": "place-button",
                        }}
                    >
                        {displayNavList}
                        <MenuItem className="text-body" onClick={toggleAddLocationModalHandler}>
                            <MDBIcon className="me-3" far icon="plus-square" /> {t("장소추가")}
                        </MenuItem>
                    </StyledMenu>
 */}

                    <div
                    /*
                        onMouseEnter={(event) => {
                            console.log("mouse ON");
                            handleClickAlarm(event);
                        }}
                        onMouseLeave={(event) => {
                            console.log("mouse OUT");
                            handleCloseAlarm();
                        }}
                            */
                    >
                        <StyledTopMenuButton
                            id="alarm-button"
                            className="text-body me-2"
                            aria-controls={open3 ? "alarm-menu" : undefined}
                            aria-haspopup="true"
                            //aria-expanded={open3 ? "true" : undefined}
                            onClick={handleClickAlarm}
                            onMouseOver={(event) => {
                                console.log("mouse ON");
                                handleClickAlarm(event);
                            }}
                            onMouseLeave={() => {
                                console.log("mouse OUT");
                                //handleCloseAlarm();
                            }}
                        >
                            {showIcon ? <MDBIcon fas icon="bell" /> : t("LOCAD0010", "알람 목록")}
                        </StyledTopMenuButton>
                        <StyledMenu
                            style={
                                {
                                    //border: "1px solid red",
                                }
                            }
                            //disableAutoFocus
                            //disableAutoFocusItem
                            onMouseOver={(event) => {
                                console.log("mouse on menu");
                            }}
                            onMouseLeave={() => {
                                console.log("onmouseleave 2");
                            }}
                            id="alarm-menu"
                            anchorEl={anchorElAlarm}
                            open={open3}
                            onClose={handleCloseAlarm}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            MenuListProps={{
                                onMouseLeave: handleCloseAlarm,
                                "aria-labelledby": "alarm-button",
                            }}
                            PaperProps={{
                                style: {
                                    width: "auto", // 원하는 크기만큼 적용
                                    //border: "1px solid red", // 원하는 스타일 적용
                                    //maxHeight: "300px", // 최대 높이 설정 (선택사항)
                                    overflow: "auto", // 내용이 많을 경우 스크롤 허용
                                },
                            }}
                        >
                            <InfiniteScroll
                                items={alarmList}
                                numberOfRendered={20}
                                scrollAmount={10}
                                itemClick={AlarmItemClickHandler}
                            />
                        </StyledMenu>
                    </div>
                    <NavLink className="text-body" to="/report-list">
                        <StyledTopMenuButton
                            id="account-button"
                            className="text-body"
                            style={{ textTransform: "none" }}
                        >
                            {showIcon ? <MDBIcon fas icon="user" /> : t("LOCAD0033", "보고서 목록")}
                        </StyledTopMenuButton>
                    </NavLink>

                    <div>
                        <StyledTopMenuButton
                            id="account-button"
                            className="text-body"
                            aria-controls={open2 ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open2 ? "true" : undefined}
                            //onClick={handleClickAccount}
                            onMouseOver={handleClickAccount}
                            //onMouseLeave={handleCloseAccount}
                            style={{ textTransform: "none" }}
                        >
                            {showIcon ? (
                                <MDBIcon fas icon="user" />
                            ) : (
                                //t("LOCAD0011", "계정정보")
                                t("LOCAD0013", "설정")
                            )}
                        </StyledTopMenuButton>
                        <StyledMenu
                            id="account-menu"
                            anchorEl={anchorElAccount}
                            open={open2}
                            onClose={handleCloseAccount}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            MenuListProps={{
                                onMouseLeave: handleCloseAccount,
                                "aria-labelledby": "account-button",
                            }}
                        >
                            <NavLink className="text-body" to="/profile">
                                <MenuItem onClick={handleCloseAccount}>
                                    {/*
                                        <MDBIcon className="me-3" fas icon="user" />
                                    */}
                                    {t("LOCAD0011", "계정정보")}
                                </MenuItem>
                            </NavLink>
                            <MenuItem onClick={toggleAddDeviceModal}>{t("LOCAD0058", "허브추가")}</MenuItem>

                            <MenuItem className="text-body" onClick={toggleAddLocationModalHandler}>
                                {t("장소추가")}
                            </MenuItem>
                            <NavLink className="text-body" to="/device">
                                <MenuItem onClick={handleCloseAccount}>
                                    {/*
                                    <MDBIcon className="me-3" fas icon="calculator" />
                                    */}
                                    {t("LOCAD0032", "장치목록")}
                                </MenuItem>
                            </NavLink>

                            <div
                                onMouseLeave={handleLanguageMenuClose}
                                style={{
                                    position: "relative",
                                    display: "inline-block",
                                }}
                            >
                                <MenuItem onMouseEnter={handleLanguageMenuOpen}>{t("LOCAD0035", "사용언어")}</MenuItem>
                                {/* 하위 메뉴 (한국어, 영어) */}
                                {isLanguageMenuOpen && true && (
                                    <div
                                        style={{
                                            //marginLeft: "20px",

                                            position: "absolute",
                                            top: 0,
                                            left: "100%",
                                            backgroundColor: "white",
                                            /*
                                                border: "1px solid red",
                                                zIndex: 1, // 다른 요소와 겹치는 문제 방지
                                                */
                                        }}
                                    >
                                        <MenuItem onClick={() => languageSelectHandler(Languages.KOR)}>
                                            {t("LOCAD0036", "한국어")}
                                        </MenuItem>
                                        <MenuItem onClick={() => languageSelectHandler(Languages.ENG)}>
                                            {t("LOCAD0037", "영어")}
                                        </MenuItem>
                                    </div>
                                )}
                            </div>
                            <MenuItem onClick={signoutHandler}>
                                {/*
                                <MDBIcon className="me-3" fas icon="sign-out-alt" />
                                    */}
                                {t("로그아웃")}
                            </MenuItem>
                        </StyledMenu>
                    </div>
                </div>
            </MDBContainer>
        </StyledMDBNavbar>
    );
};

export default Topbar;

