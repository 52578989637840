import { DeviceToPlaceParams } from 'api/device';
import { PlaceParams, queryGetPlaces } from 'api/place';
import { AlarmListParams, AlarmQuery, queryRecordAlarmGet } from 'api/record';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Auth } from 'aws-amplify';
import UpdateLocationContainer from 'containers/updateLocationContainer';
import {
    updateAlarmList,
    updateCurrentPlace,
    updatePlaceList,
} from 'features/display-slice';
import { setCurrentLocationId } from 'features/user-slice';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import LocationSideNav from './locationSideNav';
import Topbar from './topbar';
import { COLOR_MAIN_BG } from 'constants/common';
import AddDeviceContainer from 'containers/location/addDeviceContainer';

interface DashBoardProps {
    showSideNav: boolean;
    backgroundClass?: string;
    children?: any;
}

export interface OutletContext {
    placeList: PlaceParams[];
    locationId: string;
}

const DashBoard = (props: DashBoardProps) => {
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);

    const { showSideNav }: DashBoardProps = props;
    const [showAddLocationModal, setShowAddLocationModal] = useState(false);
    const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);

    const [mainClassName, setMainClassName] = useState('sidenav-main');

    /* from i18n */
    const { t, i18n } = useTranslation(['trans']);

    /* props from redux */
    const dispatch = useAppDispatch();
    const currentLocationId = useAppSelector(
        (state) => state.authState.currentLocationId
    );
    const placeList = useAppSelector((state) => state.display.placeList);
    const currentPlaceDeviceList: DeviceToPlaceParams[] = useAppSelector(
        (state) => state.display.currentPlaceDeviceList
    );

    /* react router constans */
    // navigater
    const navigate = useNavigate();
    // uuid from url params
    const { locationId } = useParams();

    /**
     * 화면 크기에 따라 sideNav를 (비)활성화 하는 함수
     * 현재 화면 크기가 1000px 아래로 내려갈 경우 sideNav를 비활성화 함
     */
    const handleDisplay = useCallback(() => {
        const sidenav = 'sidenav-main';

        //if (window.matchMedia('(max-width: 1000px)').matches || !showSideNav) {
        if (!showSideNav) {
            return setMainClassName('');
        } else if (showSideNav) {
            return setMainClassName(sidenav);
        }
    }, [showSideNav]);

    useEffect(() => {
        handleDisplay();

        window.addEventListener('resize', handleDisplay);
    }, [handleDisplay, showSideNav]);

    /**
     * 로그아웃 함수
     * return void
     */
    function signoutHandler() {
        Auth.signOut()
            .then(() => {
                navigate('/', { replace: true });
            })
            .catch((error) => {
                console.error('sign out error');
            });
    }

    /**
     * 등록된 장소들을 조회함
     * @returns 
     */
    async function getPlace() {
        const result = await queryGetPlaces(checkedUser.principalId);

        let places = [];

        if (!result?.data) return;

        places = Object.keys(result.data).map((key) => {
            return {
                uuid: key,
                ...result.data[key],
            };
        });

        // setPlaceList(places);
        dispatch(updatePlaceList(places));
    }

    useEffect(() => {
        if (!checkedUser) return;

        getPlace();
    }, [checkedUser, dispatch]);

    /* alarmList */

    /**
     * 계정에 읽지 않은 모든 알람을 조회
     * @param query 
     * @returns 
     */
    async function getAlarmList(query?: AlarmQuery) {
        const alarmList = await queryRecordAlarmGet(checkedUser?.principalId, {
            checked: false, // 읽지않은 것들만 받도록 설정값 전달.
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.info(error);
            });

        return (alarmList as AlarmListParams[]) || null;
    }

    useEffect(() => {
        async function run() {
            const alarmListResult: AlarmListParams[] = await getAlarmList();

            dispatch(updateAlarmList(alarmListResult));
        }

        if (!checkedUser) return;

        run();
    }, [checkedUser, dispatch]);

    /* sideNav */

    /* make currentLocationId from redux and url param same */
    useEffect(() => {
        const findKey = _.findKey(placeList, {
            uuid: currentLocationId,
        });

        !findKey &&
            dispatch(
                setCurrentLocationId(placeList[placeList.length - 1]?.uuid)
            );
    }, [currentLocationId, dispatch, placeList]);

    // currentPlace와 currentLocationId에 현재 장소정보를 저장
    useEffect(() => {
        if (!currentLocationId) {
            dispatch(setCurrentLocationId(placeList[0]?.uuid));
            dispatch(updateCurrentPlace(placeList[0]));
        }
    }, [locationId, placeList, navigate, dispatch, currentLocationId]);

    function toggleAddLocationModal() {
        setShowAddLocationModal(!showAddLocationModal);
    }
    function toggleAddDeviceModal() {
        setShowAddDeviceModal(!showAddDeviceModal);
    }

    function navigateClickHandler(location: PlaceParams) {
        dispatch(setCurrentLocationId(location.uuid));
        dispatch(updateCurrentPlace(location));
        navigate(`/dashboard/location`);
    }

    /* outlet context constants */
    const context = {
        placeList: [...placeList],
        locationId: locationId || currentLocationId,
    };

    return (
        <>
            <Topbar
                signout={signoutHandler}
                toggleAddLocationModal={toggleAddLocationModal}
                toggleAddDeviceModal={toggleAddDeviceModal}
                navList={placeList}
                navigateClick={navigateClickHandler}
            />
            {/** width에 따라 사이드 네비게이션 메뉴 표현 여부 */}
            <>
                {showSideNav ? (
                    <LocationSideNav
                        navList={placeList}
                        toggleModal={toggleAddLocationModal}
                        navigateClick={navigateClickHandler}
                        showMapButton={currentPlaceDeviceList.length > 0}
                    />
                ) : null}

                {/** 대쉬보드 메인화면 */}
                <main
                    className={mainClassName} // side nav 표시 여부에 따라 달라짐
                    style={{
                        //border: '3px solid red',
                        //backgroundColor: showSideNav ? '#fff' : '#eee'
                        backgroundColor: COLOR_MAIN_BG,
                    }}
                    // style={{ backgroundColor: '#fff' }}
                >
                    <Outlet context={context} />
                </main>
            </>

            {/** 장소추가 모달 */}
            <UpdateLocationContainer
                btnText="저장"
                modalTitle="장소 추가"
                placeList={placeList}
                setShowModal={setShowAddLocationModal}
                showModal={showAddLocationModal}
                toggleModalHandler={toggleAddLocationModal}
            />

            {/** 장치추가 버튼과 모달 */}
            <AddDeviceContainer
                needTable={true}
                needSubscribe={true} // 여기에서도 허브를 추가할 수 있도록  true를 전달.
                toggleModalHandler={toggleAddDeviceModal}
                showModal={showAddDeviceModal}
                setShowModal={setShowAddDeviceModal}
            />
        </>
    );
};

export default DashBoard;
