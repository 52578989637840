import { DeviceParams } from 'api/device';
import { AlarmListParams } from 'api/record';
import AlarmTable from 'components/fragment/alarmTable';
import CsvDownload from 'components/fragment/csvDownload';
import { PrevBtn } from 'components/fragment/prevBtn';
import DateSearchContainer from 'containers/dateSearchContainer';
import { MDBBtn, MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import excelIcon from 'assets/img/icon_excel_download.png';

export interface AlarmDisplayProps {
    currentDevice: DeviceParams;
    body: AlarmListParams[];
    alarmCount: number;
    loader: boolean;
    alarmSearchSubmit?;
    rowSelectClickHandler?;
    checkAlarmHandler?;
    csvDownloadClickHandler?;
    csvFileName: string;
}

const buttonStyle = {
    border: "1px solid grey", // 테두리 색상
    backgroundColor: "transparent", // 배경 투명
    color: "lightgray", // 텍스트 색상
    margin: "0 0.5rem",
    padding: "0 0.5rem", // 여백
    cursor: "pointer", // 마우스 포인터
    borderRadius: "4px", // 버튼 모서리 둥글게
    transition: "background-color 0.3s, color 0.3s", // 애니메이션 효과
    fontSize: "0.8rem", // 텍스트 크기 작게 설정
    height: "30px", // 칩의 고정 높이 설정
    display: "flex", // 칩 내부 요소 정렬
    alignItems: "center", // 수직 중앙 정렬
    justifyContent: "center", // 가로 중앙 정렬
    boxShadow: "none"
};
const AlarmDisplay = (props: AlarmDisplayProps) => {
    const {
        currentDevice,
        body,
        alarmCount,
        loader,
        alarmSearchSubmit,
        rowSelectClickHandler,
        checkAlarmHandler,
        csvDownloadClickHandler,
        csvFileName,
    } = props;

    const { t, i18n } = useTranslation(['trans']);

    return (
        <>
            <MDBContainer
            fluid
            className="subpage-container pt-2 px-lg-5"
            style={{ overflowX: 'auto' }} // 가로 스크롤을 활성화
            >
                <div className="label-header">
                    <div className="d-flex align-items-center">
                        {/* <div className="mx-3">
                            <PrevBtn />
                        </div> */}
                        <div>
                        <h2 className="m-0">{/* <h2 className="m-3"> */}
                                {t("알람")} {" | "}
                            </h2>
                        </div>
                        <div className="alarm-device-info text-nowrap text-grayish">
                            <div>
                                <small className="me-3">
                                    {t("LOCAD0023", "시리얼번호")}: {currentDevice?.deviceInfo.serial}
                                </small>
                            </div>
                            <div>
                                <small className="me-3">
                                    {t("LOCAD0024", "장치이름")}: {currentDevice?.nickname}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="ml-auto">
                        <DateSearchContainer searchSubmit={alarmSearchSubmit} />
                    </div>
                </div>
                <div className="table-box">
                    <div className="table-box-buttons d-flex justify-content-end mb-3">
                        <MDBBtn onClick={checkAlarmHandler} style={buttonStyle}>
                            {/**
                                <MDBIcon fas icon="check" />
                                     */}
                            {t("LOCAD0003", "확인")}
                        </MDBBtn>

                        {body?.length > 0 && (
                            <CsvDownload
                                //className="btn btn-primary btn-floating"
                                //btnChildren={<MDBIcon fas icon="save" />}
                                className=""
                                btnChildren={<MDBBtn style={buttonStyle}>
                                    {/**
                                    <MDBIcon far icon="file-excel" />
                                     */}
                                     <img src={excelIcon} alt="" height={"20"}/>
                                    {t("LOCAD0025", "엑셀 다운로드")} </MDBBtn>}
                                //btnChildren={null}
                                body={body}
                                csvDownloadClickHandler={csvDownloadClickHandler}
                                csvFileName={csvFileName}
                                type="alarm"
                            />
                        )}
                    </div>
                        <AlarmTable
                            body={body}
                            // alarmCount={alarmCount}
                            loader={loader}
                            // numberOfRows={15}
                            rowSelectClickHandler={rowSelectClickHandler}
                        />
                </div>
            </MDBContainer>
        </>
    );
};

export default AlarmDisplay;
