import IconBtn from 'components/fragment/iconBtn';
import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import icButtonClose from 'assets/img/icons/ic_button_close.png';

export interface UpdateLocationModalProps {
    modalTitle: string;
    showModal: boolean;
    edit?: boolean;
    toggleModalHandler: () => void;
    moveToPrevHandler?: () => void;
    addLocationSubmit?: (event) => void;
    editLocationSubmit?: (event) => void;
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
    children?: any;
    btnText?: string;
}

const StyledModalBody = styled(MDBModalBody)`
    justify-content: center !important;
    align-items: center;
    width: 460px;
    margin: 0 auto;

    .input-section.horizontal .form-control {
    border: 1px solid #292929 !important;
    border-radius: 0px !important;
    background-color: transparent !important;
    color: #292929 !important;
    font-size: 1rem !important;
    }

    .input-section.horizontal .form-control:focus,
    .input-section.horizontal .form-control:not(:placeholder-shown) {
    border: 1px solid #292929 !important;
    color: #292929 !important;
    font-size: 1rem !important;
    }

    .input-section.horizontal label {
    font-size: 1rem !important;
    color: #292929 !important;
    }

    .w-100 {
    border-radius: 0px !important;
    }

    .w-100 .MuiSelect-icon {
    color: #292929 !important;
    }
    
    .w-100 .MuiOutlinedInput-notchedOutline {
    border-color: #292929 !important;
    border-width: 1px !important;
}
`;

const CloseBtn = styled.button`
    border: none;
    background-color: transparent;
    padding: 0px; /* 테두리와 X 간의 간격 */
    cursor: pointer; /* 포인터 커서 */
    position: absolute; /* 절대 위치 */
    top: 50px; /* 상단에서 50px 떨어진 위치 */
    right: 50px; /* 오른쪽 끝에서 50px 떨어진 위치 */
`;

const CloseIconButton = ({ ariaLabel, onClick }) => (
    <CloseBtn onClick={onClick} aria-label={ariaLabel}>
        <img src={icButtonClose} alt="" />
    </CloseBtn>
);

const UpdateLocationModal = (props: UpdateLocationModalProps) => {
    const {
        modalTitle,
        showModal,
        toggleModalHandler,
        moveToPrevHandler,
        setShowModal,
        children,
        btnText,
        addLocationSubmit,
        editLocationSubmit,
        edit,
    } = props;

    let submitHandler = edit ? editLocationSubmit : addLocationSubmit;

    const { t, i18n } = useTranslation(["trans"]);

    return (
        <form onSubmit={submitHandler}>
            <MDBModal show={showModal} setShow={setShowModal} tabIndex="-1">
                <MDBModalDialog centered size="lg" scrollable>
                    <MDBModalContent
                        style={{
                            borderRadius: "0",
                            padding: "30px 26px",
                        }}
                    >
                        <CloseIconButton ariaLabel="Close" onClick={toggleModalHandler} />
                        <MDBModalHeader
                            className="modal-header"
                            style={{
                                display: "flex",
                                justifyContent: "center", // 가운데 정렬
                                /* border: 'none' */
                            }}
                        >
                            <span></span>
                            <MDBModalTitle
                                className="modal-title"
                                style={{
                                    fontSize: "2.6rem",
                                    color: "#191919",
                                    fontWeight: "bold",
                                }}
                            >
                                {t(modalTitle)}
                            </MDBModalTitle>
                        </MDBModalHeader>
                        <StyledModalBody className="p-4">{children}</StyledModalBody>
                        <MDBModalFooter>
                            <MDBBtn
                                type="submit"
                                color="primary"
                                style={{
                                    width: "100px",
                                    backgroundColor: "#292929",
                                    color: "white",
                                    fontSize: "16px",
                                    fontWeight: "300",
                                    borderRadius: "4px",
                                    boxShadow: "none",
                                }}
                            >
                                {
                                    // 저장
                                    t(btnText)
                                }
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </form>
    );
};

export default UpdateLocationModal;
