import { TablePagination } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DeviceParams, queryDeviceSubscribe } from 'api/device';
import { DeviceinfoDisplayProps } from 'containers/location/addDeviceContainer';
import { useCallback, useEffect, useState } from 'react';
import Spinner from './spinner';
import { MDBBtn } from 'mdb-react-ui-kit';
import NormalInputSection from './normalInputSection';
import { Application } from 'constants/application';
import { SettingInputs } from 'containers/deviceSettingsContainer copy';

interface TableFragProps {
    head?: string[];
    body: Object[] | DeviceParams[];
    numberOfRows?: number;
    fullHeight?: boolean;
    addClick?: (event) => void;
    onClickSubscribe?: (event) => void;
    deviceInfoDisplayProps?: DeviceinfoDisplayProps;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#292929', /* '#1266f1' */
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },

    '& th:not(th:last-child), & td:not(td:last-child)': {
        // borderRight: '2px lightgrey solid',
    },
}));

const tableColumnPlaceViewEnable = true;
const DeviceAddTable = (props: TableFragProps) => {
    const {
        body,
        fullHeight,
        addClick,
        onClickSubscribe,
        deviceInfoDisplayProps,
    }: TableFragProps = props;
    const numberOfRows = props.numberOfRows || 10;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(numberOfRows);

    const checkedUser = useAppSelector((state) => state.authState.checkedUser);

    const currentLocationId = useAppSelector((state) => state.authState.currentLocationId);
    const placeList = useAppSelector((state) => state.display.placeList);
    const [placeData, setPlaceData] = useState<Application[]>([]);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDisplay = useCallback(() => {
        if (window.matchMedia('(max-width: 600px)').matches) {
            setRowsPerPage(10);
        } else {
            setRowsPerPage(numberOfRows);
        }
    }, []);

    useEffect(() => {
        const array = Object.keys(placeList).map((index) => {
            const place = placeList[index];
            return { value: place?.uuid, text: place?.nickname };
        });
        //console.log("array", array);
        setPlaceData(array);
    }, [placeList]);

    useEffect(() => {
        handleDisplay();

        window.addEventListener('resize', handleDisplay);
    }, []);

    const selectValueChangeHandler = (event) => {
        const value = event.target.value;
        console.log("selectValueChangeHandler", value);
    };

    const buttonRender = (row: any) => {
        const disabled = deviceInfoDisplayProps[row.uuid]?.added;
        let button = (
            <button
                type="button"
                className={disabled ? "btn btn-sm btn-danger text-white" : "btn btn-sm btn-primary"}
                onClick={() => addClick(row.uuid)}
                disabled={disabled}
            >
                {disabled ? "added" : "add"}
            </button>
        );

        if (deviceInfoDisplayProps[row.uuid]?.loading) {
            button = (
                <button type="button" className="btn btn-sm btn-primary" disabled>
                    <Spinner size="sm" />
                </button>
            );
        }

        return (
            <>
                {row.subscribed ? (
                    <> {button} </>
                ) : (
                    <MDBBtn
                        className="btn-secondary"
                        size="sm"
                        onClick={(e: { preventDefault: () => void }) => {
                            //e.preventDefault();
                            onClickSubscribe(row);
                        }}
                    >
                        subscribe
                    </MDBBtn>
                )}
            </>
        );
    };

    let bodyDisplay: any = (
        <StyledTableRow>
            <StyledTableCell colSpan={100} height="400" className="text-center">
                <Spinner size="lg"></Spinner>
            </StyledTableCell>
        </StyledTableRow>
    );

    if (!body) {
        bodyDisplay = (
            <StyledTableRow>
                <StyledTableCell colSpan={100} height="400" className="text-center">
                <p>게이트웨이를 먼저 검색하세요.</p> {/* <p>Plaese search gateway first.</p> */}
                </StyledTableCell>
            </StyledTableRow>
        );
    } else if (body?.length <= 0) {
        bodyDisplay = (
            <StyledTableRow>
                <StyledTableCell colSpan={100} height="400" className="text-center">
                <p>장치가 없습니다.</p> {/* <p>There is no device.</p> */}
                </StyledTableCell>
            </StyledTableRow>
        );
    } else {
        bodyDisplay = body?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: DeviceParams) => {
            return (
                <StyledTableRow key={row.uuid}>
                    {/* <StyledTableCell component="th" scope="row">{row.nickname}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">{row.deviceInfo.model}</StyledTableCell> */}
                    <StyledTableCell align="center">{row.nickname}</StyledTableCell>
                    <StyledTableCell align="center">{row.deviceInfo.model}</StyledTableCell>
                    <StyledTableCell align="center">{row.deviceInfo.serial}</StyledTableCell>
                    <StyledTableCell align="center">{row.deviceInfo.manufacturer}</StyledTableCell>
                    {tableColumnPlaceViewEnable ? (
                        <>
                            <StyledTableCell align="center" sx={{ width: 100 }}>
                                <NormalInputSection
                                    id="place"
                                    type="select"
                                    from="setting"
                                    horizontal={true}
                                    name="locationSelect"
                                    label="장소"
                                    data={placeData}
                                    value={currentLocationId}
                                    valueChanged={selectValueChangeHandler}
                                />
                            </StyledTableCell>
                        </>
                    ) : (
                        <>
                            <StyledTableCell align="center">{row.deviceInfo.hwVersion}</StyledTableCell>
                            <StyledTableCell align="center">{row.deviceInfo.swVersion}</StyledTableCell>
                            <StyledTableCell align="center" sx={{ width: 100 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "5px",
                                    }}
                                >
                                    {buttonRender(row)}
                                </div>
                            </StyledTableCell>
                        </>
                    )}
                </StyledTableRow>
            );
        });
    }

    return (
        <div className="mt-3">
            <TableContainer
                component={Paper}
                sx={{
                    borderRadius: "0",
                    boxShadow: "none",
                    maxHeight: fullHeight ? "100%" : "500px",
                }}
            >
                <Table stickyHeader sx={{ minWidth: 900, height: "100%" }} aria-label="customized table" size="small">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">이름{/* Name */}</StyledTableCell>
                            <StyledTableCell align="center">모델명{/* MODEL */}</StyledTableCell>
                            <StyledTableCell align="center">시리얼 번호{/* Serial */}</StyledTableCell>
                            <StyledTableCell align="center">제조사{/* Manufacturer */}</StyledTableCell>
                            {tableColumnPlaceViewEnable ? (
                                <>
                                    <StyledTableCell align="center">장소</StyledTableCell>
                                </>
                            ) : (
                                <>
                                    <StyledTableCell align="center">하드웨어{/* HW */}</StyledTableCell>
                                    <StyledTableCell align="center">펌웨어{/* Firmware */}</StyledTableCell>
                                    <StyledTableCell align="center">구독 설정</StyledTableCell>
                                </>
                            )}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>{bodyDisplay}</TableBody>
                </Table>
            </TableContainer>
            {!body ? (
                ""
            ) : (
                <TablePagination
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[]}
                    component="div"
                    count={body.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={false}
                />
            )}
        </div>
    );
};

export default DeviceAddTable;
