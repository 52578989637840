import { DeviceParams } from 'api/device';
import ConfirmModal from 'components/layout/confirmModal';
import { MDBBtn, MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icButtonList from 'assets/img/icons/ic_button_list.png';
import icButtonRecycle from 'assets/img/icons/ic_button_recycle.png';

interface DeviceSecondTableProps {
    body: any;
    loader: boolean;
    showModal: boolean;
    toggleModalHandler: (event?) => void;
    selectHubDeviceListHandler: (device?: DeviceParams) => void;
    hubDeleteClickHandler: () => void;
}

const DeviceSecondTable = (props: DeviceSecondTableProps) => {
    const {
        body,
        loader,
        showModal,
        toggleModalHandler,
        selectHubDeviceListHandler,
        hubDeleteClickHandler,
    } = props;

    const { t, i18n } = useTranslation(['trans']);

    const [maxHeight, setMaxHeight] = useState<string>('500px');
    const [sm, setSm] = useState<boolean>(false);

    // @ts-ignore
    const handleDisplay = useCallback(() => {
        if (window.matchMedia('(max-height: 800px)').matches) {
            setMaxHeight('250px');
            setSm(true);
        } else {
            setMaxHeight('500px');
            setSm(false);
        }
    }, []);

    useEffect(() => {
        handleDisplay();

        window.addEventListener('resize', handleDisplay);
    }, []);

    const dummy = [
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
        { nickname: 1, modelCode: 1, id: 1 },
    ];

    const actionData = {
        columns: [
            { label: "#", field: "index" },
            { label: t("LOCAD0044", "이름"), field: "nickname", width: 300 },
            { label: t("LOCAD0045", "모델"), field: "modelCode" },
            { label: t("LOCAD0046", "아이디"), field: "id" },
            { label: "", field: "buttons", sort: false },
        ],
        rows: (body ? body : []).map((row: DeviceParams, index: number) => {
            return {
                index: index + 1,
                ...row,
                buttons: (
                    <>
                        <MDBBtn
                            outline
                            size="sm"
                            floating
                            className="call-btn"
                            onClick={() => {
                                toggleModalHandler();
                                selectHubDeviceListHandler(row);
                            }}
                            style={{
                                background: "transparent",
                                border: "none",
                                width: "32px",
                                height: "32px",
                                boxShadow: "none",
                            }}
                        >
                            {/**
                            <MDBIcon fas icon='list-ul' />
                             */}
                            <img src={icButtonList} alt="" />
                        </MDBBtn>
                        <ConfirmModal
                            btnClick={() => selectHubDeviceListHandler(row)}
                            confirmClick={hubDeleteClickHandler}
                            btnClassName="btn-table-button ms-3"
                            children={<img src={icButtonRecycle} alt="" />}
                            confirmMessage={`${t("LOCAL0009", "허브를 삭제하시겠습니까?")} '${row.nickname}'`}
                        />
                    </>
                ),
            };
        }),
    };

    return (
        <MDBDatatable
            search
            searchInputProps={{ className: "m-0" }}
            maxHeight={maxHeight}
            isLoading={loader}
            fixedHeader
            fullPagination
            hover
            sm={sm}
            data={actionData}
            dark
            className="custom-dark-table"
        />
    );
};

export default DeviceSecondTable;
