import { DeviceParams, Traits, TraitsResult } from "api/device";
import React, { useCallback } from "react";
import { findTrait } from "utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { COLOR_MAIN_BG, SYMBOL } from "constants/common";
import { DEVICE_TYPE_SSPHS } from "constants/deviceTypeConst";
import DeviceCardCommon from "./DeviceCardCommon";

export interface SsphsFragProps {
    device?: DeviceParams;
    deviceTraits?: TraitsResult;
    toggleSettingsModalHandler?: (event?) => void;
    deviceButtonClick?: (event?) => void;
    hover?: boolean;
}

const Trait = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 0.1rem;
    width: 90%; /* 전체 넓이를 줄여 가운데 정렬 */
    //margin-bottom: 1rem; /* 트레잇들 간의 세로 간격 추가 */
`;

const TraitTitle = styled.span`
    text-align: left;
    font-size: 1.2rem;
    flex: 5;
    margin: 0;
`;

/*
const TraitValue = styled.span`
    text-align: left;
    font-size: 1.3rem;
    flex: 5;
    margin: 0;
`;
*/
const TraitValue = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    flex: 5;
    margin: 0;
`;

const ValueWrapper = styled.span`
    flex: 5; /* 비율 */
    text-align: right; /* value는 오른쪽 정렬 */
    font-weight: bold;
    //border: 1px solid red;
`;

const UnitWrapper = styled.span`
    flex: 5;
    text-align: left; /* unit은 왼쪽 정렬 */
    font-size: 1.1rem;
    padding-left: 5px;
    //border: 1px solid yellow;
`;

const SsphsFrag = (props: SsphsFragProps) => {
    const { t, i18n } = useTranslation(["trans"]);

    const { device, deviceButtonClick } = props;
    //console.log("log device", device);

    //const [deviceType, setDeviceType] = useState(device?.attributes?.device_type); // 240109 cloud API response 확인완료.
    const deviceType = device?.attributes?.device_type; // 240109 cloud API response 확인완료.

    const humidity: Traits = findTrait(device?.traits, "humidity");
    const temperature: Traits = findTrait(device?.traits, "temperature");
    const batteryvoltage: Traits = findTrait(device?.traits, "batteryvoltage");
    //const temperatureUnit = temperature?.unit.includes("°") ? temperature?.unit : "°" + temperature?.unit;

    const renderTraitNew = (title, value, unit) => (
        <Trait key={title + value}>
            <TraitTitle>{title}</TraitTitle>
            <TraitValue>
                <ValueWrapper>{value}</ValueWrapper>
                <UnitWrapper>{unit}</UnitWrapper>
            </TraitValue>
        </Trait>
    );

    const renderSSPHSSensors = useCallback(() => {
        let sensors = [];

        function getSensors(sensorInfo) {
            const count = sensorInfo.length;
            for (let i: number = 0; i < count; i++) {
                const name = `sensor${i + 1}`;
                const trait = findTrait(device?.traits, name);

                if (deviceType === DEVICE_TYPE_SSPHS.EXTERNAL) {
                    const errorTrait = findTrait(device?.traits, "ext_sensor_error");
                    //console.log("sensor name", name);
                    //console.log(name, trait?.currentValue);
                    //console.log("ext_sensor_error", errorTrait?.currentValue);
                    if (errorTrait?.currentValue === true) {
                        if (trait?.currentValue === 90) {
                            // short
                        } else if (trait?.currentValue === -30) {
                            // 센서없음
                            continue;
                        }
                    }
                }
                sensors.push({
                    //name: trait.nickname,
                    name: sensorInfo[i].name,
                    value: trait?.currentValue,
                    //unit: trait?.unit,
                    unit: sensorInfo[i].unit,
                });
            }

            return sensors;
        }

        switch (deviceType) {
            case DEVICE_TYPE_SSPHS.DUST:
                sensors = getSensors([
                    { name: t("LOCAD0004", "미세먼지") + "1", unit: "ug/m³" },
                    { name: t("LOCAD0004", "미세먼지") + "2", unit: "ug/m³" },
                    { name: t("LOCAD0004", "미세먼지") + "3", unit: "ug/m³" },
                ]);
                break;
            case DEVICE_TYPE_SSPHS.PH:
                sensors = getSensors([{ name: "PH", unit: "" }]);
                break;
            case DEVICE_TYPE_SSPHS.CO2:
                sensors = getSensors([{ name: "CO2", unit: "ppm" }]);
                break;
            case DEVICE_TYPE_SSPHS.NH3:
                sensors = getSensors([{ name: "NH3", unit: "" }]);
                break;
            case DEVICE_TYPE_SSPHS.SOIL:
                sensors = getSensors([{ name: "토양수분", unit: "" }]);
                break;
            case DEVICE_TYPE_SSPHS.EXTERNAL:
                sensors = getSensors([
                    { name: t("LOCAD0005", "외부온도") + "1", unit: "°C" },
                    { name: t("LOCAD0005", "외부온도") + "2", unit: "°C" },
                ]);
                break;
            default:
                break;
        }

        //console.log("getSensors", sensors);

        return (
            <>
                {sensors.map((sensor) => {
                    return renderTraitNew(sensor.name, sensor.value, sensor.unit);
                })}
            </>
        );
    }, [device?.traits, deviceType]);

    return (
        <DeviceCardCommon
            isError={false}
            device={device}
            children={
                <>
                    {renderTraitNew(t("LOCAD0060", "습도"), humidity?.currentValue, humidity?.unit)}
                    {renderTraitNew(t("LOCAD0059", "온도"), temperature?.currentValue, SYMBOL.TEMP)}

                    {device?.modelCode === "r9iot-thermometer" &&
                        renderTraitNew(t("LOCAD0061","배터리 전압"), batteryvoltage?.currentValue, SYMBOL.VOLT)}

                    {/** Sensors */}
                    {renderSSPHSSensors()}
                </>
            }
            deviceButtonClick={deviceButtonClick}
        />
    );
};

export default React.memo(SsphsFrag);
