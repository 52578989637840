/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
export interface Application {
    value: string;
    text: string;
    disabled?: boolean;
}

export const application: Application[] = [
    {
        value: 'default',
        text: '기본',
        disabled: false,
    },
    {
        value: 'strawberry',
        text: '딸기농장',
        disabled: true,
    },
];

export const defaultReportTime = 60;

// export default application;
