import React from 'react';
import { Slider } from '@mui/material';

interface CustomSliderProps {
    marks: { value: number; label: string }[];
    min: number;
    max: number;
    value: number; // value 속성이 있어야 함
    valueLabelDisplay: 'auto';
    onChange: (event: React.SyntheticEvent | any, newValue: number) => void;
}

const CustomSlider: React.FC<CustomSliderProps> = React.memo(({ 
    marks, min, max, value, valueLabelDisplay, onChange
}) => {
    return (
        <Slider
            sx={{
                width: "90%",
                height: 3,
                color: "#999",
                "& .MuiSlider-thumb": {
                    backgroundColor: "#fff",
                },
                "& .MuiSlider-track": {
                    backgroundColor: "#999",
                    height: 0,
                },
                "& .MuiSlider-rail": {
                    backgroundColor: "#999",
                    opacity: 1,
                },
                "& .MuiSlider-markLabel": {
                    color: "#fff",
                },
                "&.Mui-disabled": {
                    color: "#999",
                    "& .MuiSlider-thumb": {
                        backgroundColor: "#999",
                    },
                    "& .MuiSlider-track": {
                        backgroundColor: "#999",
                    },
                    "& .MuiSlider-markLabel": {
                        color: "#999",
                    },
                },
            }}
            valueLabelDisplay={valueLabelDisplay}
            marks={marks}
            min={min}
            max={max}
            value={value}
            onChange={onChange}
        />
    );
});

export default CustomSlider;