import styled from '@emotion/styled';
import { DeviceParams } from "api/device";
import { getChildState, setChildState, setChildStateEtc, setChildStateMode } from 'api/r9iot';
import { useAppSelector } from 'app/hooks';
import ToggleSwitch from 'components/common/ToggleSwitch';
import CustomSlider from 'components/fragment/CustomSlider';
import{ temperatureRange } from 'components/fragment/multiRangeSection';
import { MDBBtn, MDBSpinner } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useRef, useState } from 'react';

export interface DeviceSettingDisplayProps {
    currentDevice: DeviceParams;
}

export interface SettingInputs {
    nickname?: string;
    reporttime?: number;
    placeUuid?: string;
}

export interface AlertState {
    name: string;
    stackingCount: number;
    message?: string;
}

interface ToggleProps {
    isSelected: boolean;
    handleToggle: () => void;
    disabled?: boolean;
}

interface Switch {
  swidx: number;
  on: boolean;
  curtemp: number;
}

interface ControlJson {
  idx: number;
  connection: boolean;
  switches: Switch[];
  devid: string;
  line2_short: boolean;
  line2_open: boolean;
  line1_short: boolean;
  line1_open: boolean;
  ext_short: boolean;
  ext_open: boolean;
  line2_cable_short: boolean;
  line2_cable_open: boolean;
  line1_cable_short: boolean;
  line1_cable_open: boolean;
  ext_curtemp: number;
  extern_relay: boolean;
  mode_push: boolean;
  mode_manual: boolean;
  mode_rotation: boolean;
  schedule: boolean;
  power: boolean;
  ext_starttemp: number;
  ext_stoptemp: number;
  line_starttemp: number;
  line_stoptemp: number;
  rotationtime: number;
}

const StyledTraitItem = styled.div` /* 열선제어기block 5줄 */
    width : 100%;
    display: flex;
    align-items: stretch; /* 자식 요소가 부모 높이를 모두 차지하게 */
    background-color: #2c2c2c;
    padding: 0;
    height: 80px; /* 부모 요소에 명시적인 높이를 부여 */
    margin: 1.2rem 0rem; /* 1rem; */
`;

const DivSwitch = styled.div` /* 왼쪽 title */
    width: 15%; /* 왼쪽 10% 너비 */
    height: 100%;
    display: flex;
    flex-direction: column; /* 요소들을 세로로 정렬 */
    justify-content: center;
    align-items: center;
    border-right: 1px solid #7c7c7c; /* border-right: 1px solid white; */
`;

const DivSlider = styled.div` /* 오른쪽 data */
    width: 85%; /* 오른쪽 90% 너비 */
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 80px;
    gap: 10px;
`;

const Backslash = styled.span`
    margin: 0 3rem; /* 양쪽에 0.5rem 공백 추가 */
    font-size: 1rem;
    font-weight: Medium; // font-weight: Bold;
    color: #c6c6c6; 
`;

const StyledButtonLabel = styled.span`
`;

// CSS 추가
const MultiRangeSectionContainer = styled.div`  // 전체
    display: flex;
    width: 94%;
`;

const LabelContainer = styled.div`
    // 첫 번째 섹션 (20%)
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    padding: 0rem; // padding: 0rem 1rem;
`;

const SliderContainer = styled.div`
    // 두 번째 섹션 (60%)
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
`;

const ChipContainer = styled.div`
    // 세 번째 섹션 (20%)

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0rem;
    //padding: 0rem 1rem 0rem 1rem;
`;

const StyledChip = styled.div`
    // 세 번째 섹션 '온도' bg
    background-color: #5c5c5c; /* 회색 배경색 */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 50px; /* 타원형으로 보이게 큰 라운드 */
    text-align: center;
    width: 90px; /* 고정된 너비 */
    height: 60px; /* 고정된 높이 */
    display: flex;
    flex-direction: column; /* 텍스트를 위아래로 배치 */
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.2;
`;

const ChipLabel = styled.div`
    // 세 번째 섹션 '온도' title
    font-size: 1rem;
    margin-bottom: 0.1rem;
`;

const ChipValue = styled.div`
    font-size: 1rem;
    font-weight: normal;
`;

const DeviceSettingHeatcable = () => {
    /* from redux */
    const currentDevice = useAppSelector((state) => state.display.currentDevice);
    const checkedUser = useAppSelector((state) => state.authState.checkedUser);

    // getstate response
    const [controlJson, setControlJson] = useState<ControlJson | null>(null);

    /**
     * button test values
     */
    const [isSelectedPower, setIsSelectedPower] = useState<boolean>(false);

    const [isModeManual, setIsModeManual] = useState<boolean>(false);
    const [isModePush, setIsModePush] = useState<boolean>(false);
    const [isModeRotation, setIsModeRotation] = useState<boolean>(false);

    const [isSelectedLine1On, setIsSelectedLine1On] = useState<boolean>(false);
    const [isSelectedLine2On, setIsSelectedLine2On] = useState<boolean>(false);

    const [externalTemp, setExternalTemp] = useState(0); // 외기온도 초기값 없음
    const [pipeTemp, setPipeTemp] = useState(0); // 배관온도 초기값
    const [shiftTime, setShiftTime] = useState(0); // 교대 시간 초기값 없음

    const [isLoading, setIsLoading] = useState(true);

    const debounceTimeout = useRef(null); // 타이머 ID를 저장할 useRef
    const timerRef = useRef<NodeJS.Timeout | null>(null); // 타이머 ID

    // 슬라이더를 움직이는동안은 명령 안보내고 멈추고 1초뒤에 실행되도록 함.
    const handleSliderChange = (event, key, value) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            sendXmppSetEtc(key, value);
        }, 200); // 일정 시간이 지난 뒤 명령 보냄.
    };

    // loading spinner start
    const startLoading = () => {
        setIsLoading(true);

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setIsLoading(false);
        }, 10000);
    };

    // spinner stop
    const stopLoading = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        setIsLoading(false);
    };

    const getState = () => {
        getChildState(checkedUser.principalId, currentDevice?.id)
            .then((response) => {
                stopLoading();
                if (response.status === 200) {
                    // 허브에서 받으면 둘다 바꾼다.
                    setControlJson(response?.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const sendXmppMessage = (func, params) => {
        startLoading();
        func(checkedUser.principalId, currentDevice?.id, params)
            .then(() => {
                getState();
            })
            .catch((error) => console.error(error));
    };

    const sendXmppSetEtc = (key, value) => {
        let params = {
            idx: 1,
            //rotationtime: controlJson?.rotationtime,
            //ext_starttemp: controlJson?.ext_starttemp,
            //line_starttemp: controlJson?.line_starttemp,
            rotationtime: shiftTime,
            ext_starttemp: externalTemp,
            line_starttemp: pipeTemp,
            line_stoptemp: controlJson?.line_stoptemp,
            ext_stoptemp: controlJson?.ext_stoptemp,
            [key]: value, // key 값을 동적으로 속성으로 설정
        };

        sendXmppMessage(setChildStateEtc, params);
    };
    /**
     *
     * @param key
     * @param value
     */
    const sendXmppSetMode = (key, value) => {
        let params = {
            idx: 1,
            mode_manual: isModeManual,
            mode_push: isModePush,
            mode_rotation: isModeRotation,
            [key]: value, // key 값을 동적으로 속성으로 설정
        };

        sendXmppMessage(setChildStateMode, params);
    };

    const sendXmppSetstate = (swidx, key, value) => {
        let params = {
            idx: 1,
            switches: [
                {
                    swidx: swidx,
                    [key]: value,
                },
            ],
        };

        sendXmppMessage(setChildState, params);
    };

    // didMount 될때 타이머 초기화. memory leak.
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        // hub로 직접 getstate 명령 내려서 응답 받는다.
        // 열선제어기는 서버쪽 개발이 완료되지 않았고, IO, 열선 등은 표준?과 달라서 프론트엔드에서 커스텀 한다고 한다.
        if (currentDevice && currentDevice?.id && checkedUser) {
            startLoading();
            getState();
        }
    }, [checkedUser, currentDevice]);

    /* display state end */
    useEffect(() => {
        //console.log("currentDevice", currentDevice);
    }, [currentDevice]);

    useEffect(() => {
        if (controlJson) {
            setIsSelectedPower(controlJson?.power ?? false);
            setIsModeManual(controlJson?.mode_manual ?? false);
            setIsModePush(controlJson?.mode_push ?? false);
            setIsModeRotation(controlJson?.mode_rotation ?? false);
            setIsSelectedLine1On(controlJson?.switches[0]?.on ?? false);
            setIsSelectedLine2On(controlJson?.switches[1]?.on ?? false);

            setExternalTemp(controlJson?.ext_starttemp);
            setPipeTemp(controlJson?.line_starttemp);
            setShiftTime(controlJson?.rotationtime);
        }
    }, [controlJson]);

    return (
        <>
            {
                /**
                 * API response pending: loading bar add
                 */
                isLoading && (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)", // 반투명한 배경
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 9999, // 최상위 레이어로 보이도록 설정
                        }}
                    >
                        <MDBSpinner role="status">
                            <span className="visually-hidden">Loading...</span>
                        </MDBSpinner>
                    </div>
                )
            }
            <StyledTraitItem>
                <DivSwitch>
                    배관1
                    <br />
                    배관2
                </DivSwitch>
                <DivSlider>
                    <StyledButtonLabel>전원 OFF</StyledButtonLabel>
                    <ToggleSwitch
                        isSelected={isSelectedPower}
                        handleToggle={() => {
                            const newPowerState = !isSelectedPower;
                            setIsSelectedPower(newPowerState);

                            // xmpp 직접 제어 명령 보냄
                            sendXmppSetstate(1, "power", newPowerState); // swidx 1로 고정되서 날아가야됨.

                            // 전원이 꺼지면 배관1과 배관2를 자동으로 꺼짐
                            if (!newPowerState) {
                                sendXmppSetstate(1, "on", false); // 배관1 끔.
                                sendXmppSetstate(2, "on", false); // 배관2 끔.
                            }
                        }}
                    />
                    <StyledButtonLabel>전원 ON</StyledButtonLabel>

                    <Backslash>|</Backslash>

                    <StyledButtonLabel>자동</StyledButtonLabel>
                    <ToggleSwitch
                        isSelected={isModeManual}
                        handleToggle={() => {
                            const controlValue = !isModeManual;

                            setIsModeManual(controlValue);

                            sendXmppSetMode("mode_manual", controlValue);

                            // 자동 모드가 꺼지면 배관1과 배관2를 자동으로 꺼짐
                            if (!controlValue && isSelectedPower) {
                                sendXmppSetstate(1, "on", false); // 배관1 끔.
                                sendXmppSetstate(2, "on", false); // 배관2 끔.
                            }
                        }}
                    />
                    <StyledButtonLabel>수동</StyledButtonLabel>

                    <Backslash>|</Backslash>

                    <StyledButtonLabel>알람 OFF</StyledButtonLabel>
                    <ToggleSwitch
                        isSelected={isModePush}
                        handleToggle={() => {
                            const controlValue = !isModePush;
                            //setIsModePush((prev) => !prev);
                            setIsModePush(controlValue);

                            sendXmppSetMode("mode_push", controlValue);
                        }}
                    />
                    <StyledButtonLabel>알람 ON</StyledButtonLabel>

                    <Backslash>|</Backslash>

                    <StyledButtonLabel>교대 정지</StyledButtonLabel>
                    <ToggleSwitch
                        isSelected={isModeRotation}
                        handleToggle={() => {
                            const controlValue = !isModeRotation;

                            setIsModeRotation(controlValue);

                            sendXmppSetMode("mode_rotation", controlValue);
                        }}
                    />
                    <StyledButtonLabel>교대 중</StyledButtonLabel>
                </DivSlider>
            </StyledTraitItem>
            <StyledTraitItem>
                <DivSwitch>배관1</DivSwitch>
                <DivSlider>
                    <StyledButtonLabel>가동 정지</StyledButtonLabel>
                    <ToggleSwitch
                        isSelected={isSelectedLine1On}
                        handleToggle={() => {
                            if (isSelectedPower && isModeManual) {
                                // 전원이 off일 때 비활성화
                                setIsSelectedLine1On(!isSelectedLine1On);
                                sendXmppSetstate(1, "on", !isSelectedLine1On);
                            }
                        }}
                    />
                    <StyledButtonLabel>가동 중</StyledButtonLabel>
                </DivSlider>
            </StyledTraitItem>
            <StyledTraitItem>
                <DivSwitch>배관2</DivSwitch>
                <DivSlider>
                    <StyledButtonLabel>가동 정지</StyledButtonLabel>
                    <ToggleSwitch
                        isSelected={isSelectedLine2On}
                        handleToggle={() => {
                            if (isSelectedPower && isModeManual) {
                                // 전원이 off일 때 비활성화
                                setIsSelectedLine2On(!isSelectedLine2On);
                                sendXmppSetstate(2, "on", !isSelectedLine2On);
                            }
                        }}
                    />
                    <StyledButtonLabel>가동 중</StyledButtonLabel>
                </DivSlider>
            </StyledTraitItem>

            <StyledTraitItem>
                <DivSwitch>외기온도</DivSwitch>
                <DivSlider>
                    {
                        <MultiRangeSectionContainer>
                            {/* 첫 번째 섹션 (20%) */}
                            <LabelContainer>온도 설정</LabelContainer>
                            {/* 두 번째 섹션 (60%) */}
                            <SliderContainer>
                                <CustomSlider
                                    marks={temperatureRange}
                                    min={-30}
                                    max={90}
                                    value={externalTemp}
                                    valueLabelDisplay="auto"
                                    onChange={(event, newValue) => {
                                        setExternalTemp(newValue);
                                        handleSliderChange(event, "ext_starttemp", newValue);
                                    }}
                                />
                            </SliderContainer>
                            {/* 세 번째 섹션 (20%) */}
                            <ChipContainer>
                                <StyledChip>
                                    <ChipLabel>온도</ChipLabel>
                                    <ChipValue>{externalTemp}℃</ChipValue>
                                </StyledChip>
                            </ChipContainer>
                        </MultiRangeSectionContainer>
                    }
                </DivSlider>
            </StyledTraitItem>

            <StyledTraitItem>
                <DivSwitch>배관온도</DivSwitch>
                <DivSlider>
                    {
                        <MultiRangeSectionContainer>
                            {/* 첫 번째 섹션 (20%) */}
                            <LabelContainer>온도 설정</LabelContainer>

                            {/* 두 번째 섹션 (60%) */}
                            <SliderContainer>
                                <CustomSlider
                                    marks={temperatureRange}
                                    min={-30}
                                    max={90}
                                    value={pipeTemp}
                                    valueLabelDisplay="auto"
                                    onChange={(event, newValue) => {
                                        setPipeTemp(newValue);
                                        handleSliderChange(event, "line_starttemp", newValue);
                                    }}
                                />
                            </SliderContainer>
                            {/* 세 번째 섹션 (20%) */}
                            <ChipContainer>
                                <StyledChip>
                                    <ChipLabel>온도</ChipLabel>
                                    <ChipValue>{pipeTemp}℃</ChipValue>
                                </StyledChip>
                            </ChipContainer>
                        </MultiRangeSectionContainer>
                    }
                </DivSlider>
            </StyledTraitItem>

            <StyledTraitItem>
                <DivSwitch>교대시간</DivSwitch>
                <DivSlider>
                    {
                        <MultiRangeSectionContainer>
                            {/* 첫 번째 섹션 (20%) */}
                            <LabelContainer>시간 설정</LabelContainer>

                            {/* 두 번째 섹션 (60%) */}
                            <SliderContainer>
                                <CustomSlider
                                    marks={[0, 10, 20, 30, 40, 50, 60].map((value) => ({
                                        value: value,
                                        label: value + "분",
                                    }))}
                                    min={0}
                                    max={60}
                                    value={shiftTime}
                                    valueLabelDisplay="auto"
                                    onChange={(event, newValue) => {
                                        setShiftTime(newValue);
                                        handleSliderChange(event, "rotationtime", newValue);
                                    }}
                                />
                            </SliderContainer>
                            {/* 세 번째 섹션 (20%) */}
                            <ChipContainer>
                                <StyledChip>
                                    <ChipLabel>시간</ChipLabel>
                                    <ChipValue>{shiftTime}분</ChipValue>
                                </StyledChip>
                            </ChipContainer>
                        </MultiRangeSectionContainer>
                    }
                </DivSlider>
            </StyledTraitItem>
        </>
    );
};

export default DeviceSettingHeatcable;