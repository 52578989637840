import ThermometerFrag from 'components/fragment/device/ThermometerFrag';

import { fabric } from 'fabric';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBPopconfirm, MDBPopconfirmMessage } from 'mdb-react-ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import _ from 'lodash';
import toast from 'react-hot-toast';

import styled from '@emotion/styled';
import { DeviceParams, Traits } from 'api/device';
import { PlaceParams } from 'api/place';
import ColorPicker from 'components/fragment/colorPicker';
import SwitchSection from 'components/fragment/switchSection';
import { useTranslation } from 'react-i18next';
import { findTrait } from 'utils';
import PhsStyledButton from 'components/common/PhsStyledButton';

interface ShadowCanvasProps {
    popup?: boolean;
    opacity?: number;
}

const StyledShadowCanvas = styled.div<ShadowCanvasProps>`
    visibility: ${(props) => (props.popup ? 'visible' : 'hidden')};
    z-index: 50;
    position: absolute;
    opacity: ${(props) => props.opacity};
    &:hover {
        opacity: 1;
    }
`;

interface LocationMapDisplayProps {
    currentPlaceDeviceDetailList: DeviceParams[];
    currentDevice?: DeviceParams;
    currentPlace?: PlaceParams;
    objectDblClick: (uuid) => void;
    toggleSettingsModal: (event?) => void;
}

interface ShadowObject {
    id?: string;
    left: number;
    top: number;
    width: number;
    height: number;
}

interface LocationData {
    backgroundImage: string;
    x: number;
    y: number;
    lock: boolean;
}

interface LocationDeviceData {
    id: string;
    left: number;
    top: number;
    color?: string;
}

const LocationMapDisplay = (props: LocationMapDisplayProps) => {
    const { currentPlaceDeviceDetailList, currentDevice, objectDblClick, toggleSettingsModal, currentPlace } = props;

    /* from i18n */
    const { t, i18n } = useTranslation(["trans"]);

    /* local state */
    const [popup, setPopup] = useState<boolean>(false);

    // canvas의 네모칸 한칸의 사이즈를 저장
    const [gridSize, setGridSize] = useState<number>(0);

    // 현재 마우스와 상호작용(드래그, 클릭, hover, 더블클릭 등) 하고 있는  object(온도계 object)
    const [objectLocation, setObjectLocation] = useState<any>({});

    const [basicLeft, setBasicLeft] = useState<number>(0);
    const [basicTop, setBasicTop] = useState<number>(0);
    const [basicWidth, setBasicWidth] = useState<number>(0);
    const [basicHeight, setBasicHeight] = useState<number>(0);

    // 현재 마우스와 상호작용하고 있는 object의 modal(더블클릭시 등장하는 온도계 object의 세부정보)의 좌표값
    // (canvas뒤에 있기 때문에 더블클릭 하지않으면 숨겨진 상태)
    const [shadowObject, setShadowObject] = useState<ShadowObject>({
        left: 0,
        top: 0,
        height: 0,
        width: 0,
    });

    // 좌표 값과 이미지를 데이터베이스에 저장하지 않음
    // localStorage에 저장하는(저장된) 데이터(이미지, 온도계 object의 좌표값, 색상 등)
    const [locationData, setLocationData] = useState<LocationData>(null);

    // object의 움직임을 멈춤
    const [lockMovement, setLockMovement] = useState<boolean>(false);

    // image 변경, 저장
    const [selectedImage, setSelectedImage] = useState<File>();
    const [selectedImageSrc, setSelectedImageSrc] = useState<string>();

    // shadowObject와 온도계 object 간의 gap을 설정
    const [gap, setGap] = useState<number>(10);

    // main canvas의 x축의 그리드 갯수
    const [xAxis, setXAxis] = useState<number>(16);

    // main canvas의 y축의 그리드 갯수
    const [yAxis, setYAxis] = useState<number>(9);

    // shadowObject의 투명도 설정(마우스를 hover하면 변화함)
    const [popupOpacity, setPopupOpacity] = useState<number>(0.7);

    // currently saved objects
    // 현재 main canvas 내에 존재하는 온도계 objects
    const [objects, setObjects] = useState([]);

    // 초기화 확인 모달 visible
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const toggleConfirmModal = () => setConfirmModalVisible(!confirmModalVisible);

    // 이미지 업로드 안내 모달
    const [imageModalVisible, setImageModalVisible] = useState(false);
    const toggleImageUploadModal = () => setImageModalVisible(!imageModalVisible);

    /* ref start */

    // main canvas
    const canvasRef = useRef(null);

    // shadowObject를 render하기 위한 canvas
    const shadowCanvasRef = useRef(null);

    /*  save current rendered canvas into ref state for others (useEffect or function) to able to use it
    실시간 모니터링 기능을 도입하면, canvas가 re-render되는데, react state는 이전 상태를 기억하지만 새로 render된 canvas를 인지하지 못한다.
    그러므로 state가 변할 때마다 변화된 정보를 display해야하지만, 이전 상태의 canvas를 기억하고 있기때문에 issue가 발생한다.
    강제로 renderedCanvasRef에 현재상태의 canvas를 저장함 */
    // fabric.js 관련 library 문제
    const renderedCanvasRef = useRef(null);

    // main canvas의 부모 element
    const mainContainer = useRef(null);

    // main canvas의 높이
    const locationMapBottom = useRef(null);

    // image selector
    const fileSelectRef = useRef(null);

    // shadow Object(온도계 modal)이 달라붙거나, 옮겨가는 강도를 설정함
    const snap = useMemo(() => {
        return 20;
    }, []);

    /* fabric.js basic settings */
    // object의 회전 icon, 기능을 제외시킴
    useEffect(() => {
        const controls = fabric.Object.prototype.controls;
        const rotateControls = controls.mtr;
        rotateControls.visible = false;
    }, []);

    // 생성된 main-canvas에 object를 추가함
    const addObjectCanvas = useCallback(
        (
            canvas: { add: (arg0: fabric.Rect) => void },
            info: {
                left: number;
                top: number;
                uuid: string;
                temp: number | string;
                humid: number | string;
                color?: string;
                serial: string;
                lock: boolean;
            },
            deviceType?: string | number
        ) => {
            // object 내 온도
            const text = new fabric.Text("temp", {
                fontSize: 100,
                scaleX: 0.12,
                scaleY: 0.12,
                originX: "center",
                originY: "bottom",
                fontFamily: "Helvetica",
                fontWeight: "bold",
            });

            // object 내 습도
            const serial = new fabric.Text("humid", {
                fontSize: 100,
                scaleX: 0.1,
                scaleY: 0.1,
                top: 10,
                fill: "white",
                originX: "center",
                originY: "top",
                fontFamily: "Helvetica",
            });

            // 사각형 도형
            const square = new fabric.Rect({
                width: gridSize,
                height: gridSize,
                fill: info?.color || "#292929", /* fill: info?.color || "#546D64", */
                type: "rectangle",
                stroke: "",
                originX: "center",
                originY: "center",
                rx: 10,
                ty: 10,
                hasControls: false,
                borderScaleFactor: 5,
                centeredRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                hoverCursor: "move",
            });

            // 각각의 오브젝트를 하나의 오브젝트로 합침
            const group = new fabric.Group([square, text, serial], {
                left: gridSize * info.left,
                top: gridSize * info.top,
                originX: "left",
                originY: "top",
                width: gridSize,
                height: gridSize,
                id: info.uuid,
                hasControls: false,
                borderScaleFactor: 5,
                centeredRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                lockMovementY: info.lock,
                lockMovementX: info.lock,
                hoverCursor: "move",
            });

            // text object를 각각 온도, 습도로 표기하도록 변경함
            group.item(1).set({
                text: `${info.temp}°C\n${info.humid}%`,
                fill: "white",
            });
            group.item(2).set({
                text: `${info.serial}`,
            });

            //main canvas에 object로 추가
            canvas.add(group);
            // canvas.add(text);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gridSize, yAxis, xAxis]
    );

    /* amount될 때 호출됨 */
    useEffect(() => {
        if (!canvasRef.current) return;

        // main canvas를 생성
        const canvas = new fabric.Canvas(canvasRef.current, {
            selection: false,
            backgroundColor: "#e9e9e9",/* "#eee" */
            hasControls: false,
            hasBorders: false,
            lockScalingX: true,
            lockScalingY: true,
            lockMovementX: true,
            lockMovementY: true,
            hoverCursor: "arrow",
        });

        // grid minimum is 50px
        // main canvas가 생성됨에 따라 화면의 크기를 측정하고, 사용자의 화면에 맞는 grid 한칸의 수치를 계산
        const grid =
            Math.floor((mainContainer.current?.offsetHeight - locationMapBottom.current?.offsetHeight) / yAxis) < 50
                ? 50
                : Math.floor((mainContainer.current?.offsetHeight - locationMapBottom.current?.offsetHeight) / yAxis);

        setGridSize(grid);

        const canvasWidth = grid * xAxis;
        const canvasHeight = grid * yAxis;

        canvas.setWidth(canvasWidth);
        canvas.setHeight(canvasHeight);

        // canvas에 선을 그음
        for (let i = 0; i < canvasWidth / grid; i++) {
            if (i === 0) continue;

            canvas.add(
                new fabric.Line([i * grid, 0, i * grid, canvasHeight], {
                    type: "line",
                    stroke: "rgba(255,255,255, 0.7)",
                    selectable: false,
                })
            );

            canvas.add(
                new fabric.Line([0, i * grid, canvasWidth, i * grid], {
                    type: "line",
                    stroke: "rgba(255,255,255, 0.7)",
                    selectable: false,
                })
            );
        }

        /* canvas event start */

        // 온도계 object mouse click event 발생 시 호출
        canvas.on("mouse:down", (options: any) => {
            if (options.target && options.target.id) {
                setObjectLocation({
                    top: options.target.top,
                    left: options.target.left,
                    width: options.target.width,
                    height: options.target.height,
                    color: options.target.item(0).fill,
                });
                objectDblClick(options.target?.id);
            }

            if (!options.target) {
                setPopup(false);
            }
        });

        // 온도계 object mouse click event 발생 시 호출
        canvas.on("mouse:dblclick", function (options) {
            if (options.target?.id) {
                setObjectLocation({
                    top: options.target.top,
                    left: options.target.left,
                    width: options.target.width,
                    height: options.target.height,
                    color: options.target.item(0).fill,
                });
                // 더블클릭시 온도계 object modal을 open
                setPopup(true);
                objectDblClick(options.target?.id);
            }
        });

        // 온도계 object mouse click event 발생 시 호출
        canvas.on("object:moving", (options) => {
            //detail target location
            const targetDetail = { ...options.target };

            //make target grid location
            options.target.set({
                left: Math.round(options.target.left / grid) * grid,
                top: Math.round(options.target.top / grid) * grid,
            });

            //moving inside of edge
            // x axis
            if (options.target.left < 0) {
                options.target.set({
                    left: 0,
                });
            }

            if (options.target.left > canvasWidth - grid) {
                options.target.set({
                    left: canvasWidth - grid,
                });
            }

            // y axis
            if (options.target.top < 0) {
                options.target.set({
                    top: 0,
                });
            }

            if (options.target.top > canvasHeight - grid) {
                options.target.set({
                    top: canvasHeight - grid,
                });
            }

            const objects = canvas?.getObjects().filter((object) => !!object.id);
            const anotherObject = objects.find(
                (object) =>
                    object.left === options.target.left &&
                    object.top === options.target.top &&
                    object.id !== options.target.id
            );

            //prevent object location duplicate
            // object가 같은 좌표에 상호작용되는 것을 막음
            if (!!anotherObject) {
                if (targetDetail.top + targetDetail.height + snap > anotherObject.top) {
                    options.target.set({
                        top: options.target.top + grid,
                    });
                }

                // object가 이미 존재하는 다른 오브젝트위로 드래그 할 때, 위나, 아래로 좌표를 수정함
                if (options.target.top > canvasHeight - grid && anotherObject.top + anotherObject.height - snap) {
                    options.target.set({
                        top: options.target.top - grid * 2,
                    });
                }
            }

            setPopupOpacity(0.3);
            setObjectLocation({
                top: options.target.top,
                left: options.target.left,
                width: options.target.width,
                height: options.target.height,
            });
        });

        // 온도계 object가 드래그되어 옮겨져 좌표가 수정되는 event가 발생시 호출
        canvas.on("object:modified", (options) => {
            const newWidth = Math.round(options.target.width / grid) * grid;
            const newHeight = Math.round(options.target.height / grid) * grid;

            options.target.set({
                width: newWidth,
                height: newHeight,
                /* scaleX: imageResized.current,
                scaleY: imageResized.current, */
            });

            setPopupOpacity(0.7);
            setObjectLocation({
                id: options.target.id,
                top: options.target.top,
                left: options.target.left,
                width: options.target.width,
                height: options.target.height,
                color: options.target.item(0).fill,
            });
        });

        //save current rendered canvas into ref state for others (useEffect or function) to able to use it
        renderedCanvasRef.current = canvas;

        return () => canvas.dispose();
    }, [yAxis, xAxis]);

    /* lockMovement */
    // 드래그 잠금
    const toggleLockMovementHandler = useCallback(
        (__: any) => {
            setLockMovement(!lockMovement);
            setLocationData({ ...locationData, lock: !lockMovement });
        },
        [locationData, lockMovement]
    );

    useEffect(() => {
        const canvas = renderedCanvasRef.current;

        canvas.forEachObject((object) => {
            if (!!object?.id) {
                object.lockMovementX = lockMovement;
                object.lockMovementY = lockMovement;
            }
        });
    }, [lockMovement]);
    /* lockMovement */

    /* add Object to Canvas */
    // add object as canvas render (first object api will be into here)
    // object가 render된 main canvas에 추가됨
    useEffect(() => {
        const canvas = renderedCanvasRef.current;

        if (!canvas) return;

        canvas.forEachObject((object) => {
            if (object.id) canvas.remove(object);
        });

        let row = 1;

        const firstRenderLocationData: LocationData = JSON.parse(localStorage.getItem(currentPlace?.uuid));

        //console.log("currentPlaceDeviceDetailList", currentPlaceDeviceDetailList);
        currentPlaceDeviceDetailList?.forEach((deviceDetail, index) => {
            // error check. redux timing
            if(!deviceDetail) return;
            if (index + 1 > row * xAxis) {
                ++row;
            }

            const deviceData: LocationDeviceData = JSON.parse(localStorage.getItem(deviceDetail.uuid));

            const humidity: Traits = findTrait(deviceDetail?.traits, "humidity");
            const temperature: Traits = findTrait(deviceDetail?.traits, "temperature");

            deviceData
                ? addObjectCanvas(canvas, {
                      left: deviceData.left,
                      top: deviceData.top,
                      uuid: deviceData.id,
                      temp: temperature?.currentValue,
                      humid: humidity?.currentValue,
                      color: deviceData.color,
                      serial: deviceDetail.nickname,
                      lock: lockMovement,
                  })
                : addObjectCanvas(canvas, {
                      left: index,
                      top: row - 1,
                      uuid: deviceDetail.uuid,
                      temp: temperature?.currentValue,
                      humid: humidity?.currentValue,
                      serial: deviceDetail.nickname,
                      lock: lockMovement,
                  });
        });

        setObjects(canvas.getObjects().filter((object) => !!object.id));

        if (_.isEmpty(locationData) && firstRenderLocationData) {
            setLocationData(firstRenderLocationData);
            setSelectedImageSrc(firstRenderLocationData.backgroundImage);
            setYAxis(firstRenderLocationData.y || yAxis);
            setXAxis(firstRenderLocationData.x || xAxis);
            setLockMovement(firstRenderLocationData.lock);
            return;
        }

        //calculate maximum left, top number of objects
        const max = canvas
            .getObjects()
            .filter((object) => {
                return !!object.id;
            })
            .reduce(
                (acc, cur) => {
                    return {
                        top: Math.max(acc.top, cur.top),
                        left: Math.max(acc.left, cur.left),
                    };
                },
                { top: 0, left: 0 }
            );

        if (max.top / gridSize >= yAxis) {
            setYAxis(max.left / gridSize);
        }

        if (max.left / gridSize > xAxis) {
            setXAxis(max.left / gridSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addObjectCanvas, gridSize, xAxis, yAxis, currentPlaceDeviceDetailList]);
    /* after fabric js drawing canvas, save location information of canvas container  */
    useEffect(() => {
        const canvasContainer: HTMLDivElement = document.querySelector(".canvas-container")!;
        setBasicLeft(canvasContainer?.offsetLeft);
        setBasicTop(canvasContainer?.offsetTop);
        setBasicWidth(canvasContainer?.offsetWidth);
        setBasicHeight(canvasContainer?.offsetHeight);
    }, [objectLocation]);

    /* change popup location */
    const changePopupLocation = useCallback(
        (location) => {
            const duplicate = { ...location };

            //when popup width is over x axis of canvas container, change direction of popup and gap between object and popup
            if (location.left + location.width + shadowCanvasRef.current?.offsetWidth + gap > basicWidth) {
                duplicate.left = location.left - gridSize - shadowCanvasRef.current.offsetWidth;

                setGap(-10);
            } else {
                setGap(10);
            }

            //when popup height is over y axis of canvas container, popup set bottom to object
            if (location.top + shadowCanvasRef.current?.offsetHeight > basicHeight) {
                duplicate.top = location.top - shadowCanvasRef.current.offsetHeight + gridSize;
            }

            if (_.isEmpty(duplicate)) return;

            setShadowObject({
                ...shadowObject,
                left: basicLeft + duplicate.left + gridSize,
                top: duplicate.top,
            });
        },
        [basicHeight, basicLeft, basicWidth, gap, gridSize, shadowObject]
    );

    // object 드래그, column, row 추가/삭제 할 때 object data를 localStorage에 저장
    useEffect(() => {
        changePopupLocation(objectLocation);
        if (objectLocation.id) {
            const locationData = {
                id: objectLocation.id,
                left: objectLocation.left / gridSize,
                top: objectLocation.top / gridSize,
                color: objectLocation?.color,
            };

            localStorage.setItem(objectLocation.id, JSON.stringify(locationData));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objectLocation, popup, yAxis, xAxis]);
    /* changePopupLocation */

    const showErrorAlertDark = (message) => {
        toast(message, {
            icon: "🚩",
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    /* xAxis, yAxis */
    // row, column 추가/삭제
    const setAxisHandler = useCallback(
        (axis: string, number: number) => {
            const currentColumnLeft = gridSize * xAxis;
            const currentRowTop = gridSize * yAxis;

            const type = {
                x: () => {
                    setXAxis(xAxis + number);
                    setLocationData({ ...locationData, x: xAxis + number });
                },
                y: () => {
                    setYAxis(yAxis + number);
                    setLocationData({ ...locationData, y: yAxis + number });
                },
            };

            let checkIfRemovableObjectOnX = [...objects].find((object) => object.left === currentColumnLeft - gridSize);

            let checkIfRemovableObjectOnY = [...objects].find((object) => object.top === currentRowTop - gridSize);

            if (number < 0) {
                if (checkIfRemovableObjectOnX && axis === "x") {
                    return showErrorAlertDark(t("LOCAL0001", "LOCAL0001-더이상 줄일 수 없습니다."));
                }

                if (checkIfRemovableObjectOnY && axis === "y") {
                    return showErrorAlertDark(t("LOCAL0001", "LOCAL0001-더이상 줄일 수 없습니다."));
                }
            }

            type[axis]();
        },
        [gridSize, locationData, objects, xAxis, yAxis]
    );
    /* xAxis, yAxis */

    /* background image change */
    // background 이미지 설정
    const changeBackgroundImage = useCallback(
        (canvas) => {
            const image = new Image();

            if (!selectedImageSrc) {
                return canvas.setBackgroundImage(new fabric.Image(""), () => canvas.renderAll(canvas));
            } else {
                image.onload = () => {
                    const fabricImage = new fabric.Image(image);

                    canvas.setBackgroundImage(fabricImage, () => canvas.renderAll(canvas), {
                        opacity: 0.3,
                        stretch: true,
                        scaleX: canvas.width / image.width,
                        scaleY: canvas.height / image.height,
                    });
                };

                image.src = selectedImageSrc || locationData.backgroundImage;
            }
        },
        [selectedImageSrc]
    );

    useEffect(() => {
        changeBackgroundImage(renderedCanvasRef.current);
    }, [changeBackgroundImage, selectedImage, selectedImageSrc, yAxis, xAxis]);

    const changeSelectedImageHandler = useCallback(
        (event) => {
            //check if 'cancel' button was clicked on windows file seletor interface
            if (event.target.files.length <= 0) return;

            // check if selected file is image type
            if (!event.target.files[0].type.includes("image")) {
                //return alert("Check if it's correct file extension");
                // TODO : trans
                return alert("이미지 파일만 업로드 할 수 있습니다.");
            }

            setSelectedImage(event.target.files[0]);

            const fileReader = new FileReader();
            fileReader.onload = function (readerEvent) {
                setSelectedImageSrc(readerEvent.target.result as string);
                setLocationData({
                    ...locationData,
                    backgroundImage: readerEvent.target.result as string,
                });
            };
            fileReader.readAsDataURL(event.target.files[0]);

            //after setting state of image information currently selected, then clear file input element for preventing that not showing file select interface while selecting same image twice
            //clear file input element
            event.target.value = "";
        },
        [locationData]
    );

    function handleDefaultImageConfirmClick() {
        setSelectedImage(null);
        setSelectedImageSrc(null);
        setLocationData({
            ...locationData,
            backgroundImage: null,
        });
    }

    // 기본이미지
    const changeToBasicImageHandler = useCallback(() => {
        const message = "Are you sure to back to basic background image?";

        console.log("asdf")
        //if (!window.confirm(message)) return;
        setConfirmModalVisible(true);

        }, [setConfirmModalVisible]);
    /* background image change */

    /**
     * location 관련 ui 조작시 localStorage에 데이터 저장
     */
    useEffect(() => {
        if (_.isEmpty(locationData)) return;

        localStorage.setItem(currentPlace?.uuid, JSON.stringify(locationData));
    }, [currentPlace, locationData]);

    // object 색상 변경
    function colorChangeHandler(event) {
        const color = event.target.value;
        const device = renderedCanvasRef.current.getObjects().find((object) => object.id === currentDevice.uuid);

        device.item(0).set({ fill: color });
        setObjectLocation({ ...objectLocation, color, id: currentDevice.uuid });
    }

    const renderCanvas = () => {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        /* overflow: "auto", */
                        width: "100%",
                        height: "95%",
                    }}
                >
                    <canvas
                        id="canvas"
                        ref={canvasRef}
                        style={{
                            border: "1px solid #ccc",
                        }}
                    ></canvas>
                    <StyledShadowCanvas
                        style={{
                            transition: "all .18s ease-in-out, visibility 0s",
                            top: shadowObject.top,
                            left: shadowObject.left + gap,
                        }}
                        id="shadow-canvas"
                        ref={shadowCanvasRef}
                        popup={popup}
                        opacity={popupOpacity}
                    >
                        <ThermometerFrag
                            device={currentDevice}
                            key={`device_${currentDevice?.uuid}`}
                            toggleSettingsModalHandler={toggleSettingsModal}
                        ></ThermometerFrag>
                        <ColorPicker colorChange={colorChangeHandler} currentColor={objectLocation.color} />
                    </StyledShadowCanvas>
                </div>
            </>
        );
    };

    const renderBottom = () => {
        const controlButtonWidth = "80px";
        const imageButtonWidth = "120px";
        return (
            <div className="location-map-bottom" ref={locationMapBottom}>
                <div className="grid-control">
                    <PhsStyledButton
                        onClick={() => {
                            setAxisHandler("y", 1);
                        }}
                        inverted
                        width={controlButtonWidth}
                        disabled={lockMovement}
                    >
                        row +
                    </PhsStyledButton>

                    <PhsStyledButton
                        onClick={() => {
                            setAxisHandler("y", -1);
                        }}
                        inverted
                        width={controlButtonWidth}
                        disabled={lockMovement}
                    >
                        row -
                    </PhsStyledButton>

                    <PhsStyledButton
                        onClick={() => {
                            setAxisHandler("x", 1);
                        }}
                        inverted
                        width={controlButtonWidth}
                        disabled={lockMovement}
                    >
                        col +
                    </PhsStyledButton>
                    <PhsStyledButton
                        onClick={() => {
                            setAxisHandler("x", -1);
                        }}
                        inverted
                        width={controlButtonWidth}
                        disabled={lockMovement}
                    >
                        col -
                    </PhsStyledButton>
                </div>

                <span>
                    <SwitchSection
                        className="m-0"
                        label={"잠금"}
                        name={"lock"}
                        id={"lock"}
                        toggleSwitchHandler={toggleLockMovementHandler}
                        checked={lockMovement}
                        inline
                    />
                </span>
                <div className="d-flex" style={{ gap: "6px" }}> {/* style={{ gap: "3px" }}> */}
                    <PhsStyledButton onClick={toggleImageUploadModal} width={imageButtonWidth}>
                        {t("이미지 업로드")}
                    </PhsStyledButton>
                    <PhsStyledButton onClick={toggleConfirmModal} width={imageButtonWidth}>
                        {t("기본 이미지")}
                    </PhsStyledButton>
                </div>
            </div>
        );
    };

    return (
        <>
            <MDBContainer fluid className="main-container" ref={mainContainer}>
                <div
                    className="location-map-container"
                    id="canvas-container"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "90%",
                        margin: "0 auto",   // 가운데정렬
                        overflowX: 'auto',
                    }}
                >


                    {
                        // buttons
                        renderBottom()
                    }
                    {
                        // D&D canvas를 그린다
                        renderCanvas()
                    }
                </div>
            </MDBContainer>

            <MDBModal tabIndex="-1" show={imageModalVisible} setShow={setImageModalVisible}>
                <MDBModalDialog centered size="lg">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>안내</MDBModalTitle>
                            <MDBBtn className="btn-close" color="none" onClick={toggleImageUploadModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <br />
                            <p>
                                - 배경 이미지는 현재PC에만 적용 됩니다.
                                <br />
                                - 배경 이미지는 타일 변경시 배경에 맞게 비율이 자동조절됩니다.
                                <br />
                            </p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn
                                onClick={() => {
                                    toggleImageUploadModal();
                                    fileSelectRef.current.click();
                                }}
                            >
                                {t("LOCAD0003", "LOCAD0003-확인")}
                                <input
                                    type="file"
                                    className="d-none"
                                    id="imageSelector"
                                    onInput={changeSelectedImageHandler}
                                    ref={fileSelectRef}
                                />
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal
                animationDirection="top"
                show={confirmModalVisible}
                setShow={setConfirmModalVisible}
                tabIndex="-1"
            >
                <MDBModalDialog position="top" frame={true}>
                    <MDBModalContent>
                        <MDBModalBody className="py-1">
                            <div className="d-flex justify-content-center align-items-center my-3">
                                <p className="mb-0">{t("배경 이미지가 기본으로 되돌아갑니다.\n진행하시겠습니까?")}</p>
                                <MDBBtn
                                    color="primary"
                                    size="sm"
                                    className="ms-2"
                                    onClick={() => {
                                        toggleConfirmModal();
                                        handleDefaultImageConfirmClick();
                                    }}
                                >
                                    {t("LOCAD0001", "LOCAD0001-네")}
                                </MDBBtn>
                                <MDBBtn color="info" size="sm" className="ms-2" onClick={toggleConfirmModal}>
                                    {t("LOCAD0002", "LOCAD0002-아니오")}
                                </MDBBtn>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
};

export default LocationMapDisplay;
